import { TextField } from '@mui/material';
import { ChangeEvent } from 'react';

type UploadAreaProps = {
  multiple: boolean;
  onUpload: (files: FileList) => void;
  disabled?: boolean;
};

export function UploadArea({ multiple, onUpload, disabled }: UploadAreaProps) {
  return (
    <TextField
      type="file"
      disabled={disabled}
      inputProps={{ accept: 'audio/*, application/zip', multiple: multiple }}
      sx={{
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files) {
          onUpload(files);
        }
      }}
    />
  );
}
