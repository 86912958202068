import { create } from 'zustand';
import { Folder } from '../@types/folders';
import { MoveSongRequest, Song } from '../@types/songs';
import folderService from '../services/folderService';
import songsService from '../services/songsService';
import sharingService from '../services/sharingService';
import { SharedUserInfo } from '../@types/sharing';

type FoldersStoreState = {
  folders: Folder[];
  sharedFolderUsers: SharedUserInfo[];
  foldersError: Error | string | null;
  isFoldersLoading: boolean;
  isRenameLoading: string;
};

type FoldersStoreActions = {
  getFolders: () => Promise<Folder[]>;
  createFolder: (name: string) => Promise<Folder | undefined>;
  deleteFolder: (folderId: string) => Promise<void>;
  renameFolder: (folderId: string, newName: string) => Promise<void>;
  renameSongInSidebar: (folderId: string, songId: string, newName: string) => void;
  renameSong: (folderId: string, songId: string, newName: string) => Promise<void>;
  createSongInFolder: (folderId: string, song: Song) => void;
  deleteFolderSong: (folderId: string, songId: string) => void;
  moveFolderSong: (data: MoveSongRequest) => Promise<void>;
  shareFolderByInvite: (data: any) => Promise<void>;
  getFolderSharedUsers: (folderId: string) => Promise<void>;
  deleteFolderSharedUser: (email: string, folderId: string) => Promise<void>;
};
export const defaultFolderName = 'default_folder_for_uploads';
export const useFoldersStore = create<FoldersStoreState & FoldersStoreActions>((set, get, store) => ({
  folders: [],
  sharedFolderUsers: [],
  foldersError: null,
  isFoldersLoading: false,
  isRenameLoading: '',
  getFolders: async () => {
    try {
      set({ isFoldersLoading: true });

      const folders = await folderService.getFolders();

      const defaultFolderIndex = folders.findIndex(folder => folder.name === defaultFolderName);
      if (defaultFolderIndex === -1) {
        folders.push(await folderService.createFolder(defaultFolderName));
      }

      set({ folders, isFoldersLoading: false, foldersError: null });

      return folders;
    } catch (e: any) {
      set({ folders: [], foldersError: e.message, isFoldersLoading: false });
      return [];
    }
  },
  createFolder: async name => {
    try {
      const folder = await folderService.createFolder(name);

      set({ folders: [...get().folders, folder], foldersError: null });

      return folder;
    } catch (e: any) {
      set({ folders: [...get().folders], foldersError: e.message });
    }
  },
  deleteFolder: async folderId => {
    try {
      await folderService.deleteFolder(folderId);

      set({ folders: get().folders.filter(folder => folder.id !== folderId), foldersError: null });
    } catch (e: any) {
      set({ folders: [...get().folders], foldersError: e.message });
    }
  },
  renameFolder: async (folderId, newName) => {
    try {
      set({ isRenameLoading: folderId });
      await folderService.renameFolder(folderId, newName);

      set({
        folders: get().folders.map(folder => (folder.id === folderId ? { ...folder, name: newName } : folder)),
        foldersError: null,
        isRenameLoading: ''
      });
    } catch (e: any) {
      set({ folders: [...get().folders], foldersError: e.message });
    }
  },
  renameSongInSidebar: (folderId, songId, newName) => {
    set({
      folders: get().folders.map(folder => {
        if (folder.id === folderId) {
          const editedSongs = folder.songs.map(song => {
            if (song.id === songId) {
              return { ...song, name: newName };
            }

            return song;
          });

          return { ...folder, songs: editedSongs };
        }

        return folder;
      })
    });
  },
  renameSong: async (folderId, songId, newName) => {
    try {
      get().renameSongInSidebar(folderId, songId, newName);
    } catch (e: any) {
      set({ foldersError: e.message });
    }
  },
  createSongInFolder: (folderId, song) => {
    set({
      folders: get().folders.map(folder =>
        folder.id === folderId
          ? {
              ...folder,
              songs: [...folder.songs, song]
            }
          : folder
      )
    });
  },
  deleteFolderSong: (folderId, songId) => {
    set({
      folders: get().folders.map(folder =>
        folder.id === folderId ? { ...folder, songs: folder.songs.filter(song => song.id !== songId) } : folder
      )
    });
  },
  moveFolderSong: async data => {
    try {
      await songsService.moveSong(data);

      const song = get()
        .folders.find(folder => folder.id === data.currentFolderId)
        ?.songs.find(song => song.id === data.songId);

      if (song) {
        song.folderId = data.newFolderId;

        get().deleteFolderSong(data.currentFolderId, data.songId);

        set({
          folders: get().folders.map(folder =>
            folder.id === data.newFolderId
              ? {
                  ...folder,
                  songs: [...folder.songs, song]
                }
              : folder
          )
        });
      }
    } catch (e: any) {
      set({ foldersError: e.message });
    }
  },
  shareFolderByInvite: async data => {
    try {
      await sharingService.shareFolderByInvite(data);

      await get().getFolderSharedUsers(data.folderId);
    } catch (error) {}
  },
  getFolderSharedUsers: async folderId => {
    try {
      const sharedFolderUsers = await sharingService.getSharedUsersByFolderId(folderId);

      set({ sharedFolderUsers, foldersError: null });
    } catch (e: any) {
      set({ sharedFolderUsers: [], foldersError: e.message });
    }
  },
  deleteFolderSharedUser: async (email: string, folderId: string) => {
    try {
      await sharingService.deleteFolderSharedUser(email, folderId);

      set({ sharedFolderUsers: get().sharedFolderUsers.filter(sharedUser => sharedUser.email !== email) });
    } catch (e: any) {
      set({ foldersError: e.message });
    }
  }
}));
