import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useUploadFilesSelector } from '../store/selectors/uploadFiles.selector';
import { useUserSongAccessStore, useUserStore } from '../store/user.store';
import { accessType } from '../utils/songAccessType';
import { useNavigate } from 'react-router-dom';

function useSongAccess(songId?: string) {
  const { user: auth0User } = useAuth0();
  const navigate = useNavigate();
  const user = useUserStore(state => state.user);
  const songAccess = useUserSongAccessStore(state => state.songAccessType);
  const getSongAccessType = useUserSongAccessStore(state => state.getSongAccessType);



  useEffect(() => {
    if (songId) {
      
      getSongAccessType(songId).then((songAccess) => {
        if(!songAccess && auth0User) {
          navigate('/request-access')
        }
        if (!songAccess && !auth0User) {
          navigate('/not-found')
        }
      })
    }
  }, [songId]);


  const canDownload = accessType.canDownload(songAccess);
  const canEdit = accessType.canEdit(songAccess);
  const isOwner = accessType.isOwner(songAccess)
  const canView = accessType.canView(songAccess);

  return {
    canDownload,
    canEdit,
    isOwner,
    canView,
    ownerEmail: 'ownerEmail',
    userId: user?.id,
  };
}

export default useSongAccess;