export const formatDateWDM = (createdAt: string, withSlash?: boolean): string => {
  const date = new Date(createdAt);

  const day = date.getDate();
  const weekday = date.toLocaleString('en-US', { weekday: 'short' });
  const month = date.toLocaleString('en-US', { month: 'short' });

  return `${weekday} ${day} ${month}`;
};

export function formatDateMDY(date: Date): string {
  let month: number = date.getMonth() + 1;
  let day: number = date.getDate();
  let year: number = date.getFullYear() % 100;

  let monthStr: string = month.toString();
  let dayStr: string = day.toString();
  let yearStr: string = year.toString();

  return `${monthStr}/${dayStr}/${yearStr}`;
}

export const formatDateMDTime = (createdAt: string): string => {
  const date = new Date(createdAt);

  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const hour = date.getHours() % 12 || 12;
  const minute = date.getMinutes();
  const period = date.getHours() < 12 ? 'am' : 'pm';

  return `${month} ${day} at ${hour}:${minute.toString().padStart(2, '0')} ${period}`;
};
