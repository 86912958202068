import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Button, IconButton, MenuItem, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SongVersion } from '../../@types/songs';
import DeleteSongVersionDialog from '../../components/DeleteSongVersionDialog';
import { VersionsDialog } from '../../components/multitrack/VersionsDialog';
import UploadNewSongVersionDialog from '../../components/UploadNewSongVersionDialog';
import { usePlaylistStore } from '../../store/players.store';
import { useFoldersSelector } from '../../store/selectors/folders.selector';
import { useSongSelector } from '../../store/selectors/song.selector';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down.svg';

export type SongVersionsProps = {
  canEdit: boolean;
  turnOffSong: () => void;
  turnOffPlaylist: () => void;
  setSoloMutedStems: (soloMutedStems: any) => void;
};

export function SongVersions({ canEdit, turnOffSong, turnOffPlaylist, setSoloMutedStems }: SongVersionsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const { folderId, songId } = useParams();
  const { currentVersion, songVersions, changeVersion, getSongWithStems, deleteVersion, song } = useSongSelector();
  const { renameSongInSidebar, deleteFolderSong } = useFoldersSelector();
  const init = usePlaylistStore(state => state.init);
  const handleIsStemsRendered = usePlaylistStore(state => state.handleIsStemsRendered);
  const handleIsSongRendered = usePlaylistStore(state => state.handleIsSongRendered);
  const setStemsCountForLoading = usePlaylistStore(state => state.setStemsCountForLoading);
  const [isVersionsOpened, setIsVersionsOpened] = useState(false);
  const [versionOnDelete, setVersionOnDelete] = useState<SongVersion | null>(null);
  const [isUploadNewVersionDialogOpen, setIsUploadNewVersionDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleChangeVersion = async (versionId: string) => {
    const currentVersion = await changeVersion(versionId);
    const playListState = init(currentVersion!);

    turnOffSong();
    turnOffPlaylist();

    await getSongWithStems().then(files => {
      if (playListState?.soloMutedStems) {
        setSoloMutedStems(playListState?.soloMutedStems);
      } else {
        setSoloMutedStems(() =>
          files.stems?.reduce((map, stem) => map.set(stem.id, { id: stem.id, solo: false, muted: false }), new Map())
        );
      }

      setStemsCountForLoading(files.stems.length, currentVersion!);

      if (!files.stems.length) {
        handleIsStemsRendered(true, currentVersion!);
      }
    });
  };

  const handleDeleteSongVersion = async () => {
    await deleteVersion(versionOnDelete?.id!);

    enqueueSnackbar('Version deleted successfully', { variant: 'success' });

    const currentVersion = versionOnDelete;
    const versions = songVersions;
    const filteredVersions = songVersions.filter(v => v !== currentVersion);

    if (filteredVersions?.length) {
      const newVersion = await changeVersion(filteredVersions?.at(filteredVersions?.length - 1)?.id!);
      const playersState = init(newVersion!);
      turnOffSong();
      turnOffPlaylist();

      await getSongWithStems().then(async files => {
        if (!files.song) {
          handleIsSongRendered(true, newVersion!);
        }
        if (playersState?.soloMutedStems) {
          setSoloMutedStems(playersState?.soloMutedStems);
        } else {
          setSoloMutedStems(() =>
            files.stems?.reduce((map, stem) => map.set(stem.id, { id: stem.id, solo: false, muted: false }), new Map())
          );
        }

        if (currentVersion?.id === versions.sort((a, b) => a.versionNumber - b.versionNumber).at(songVersions.length - 1)?.id) {
          renameSongInSidebar(folderId!, songId!, files.song.name);
        }

        setStemsCountForLoading(files.stems.length, songId!);

        if (!files.stems.length) {
          handleIsStemsRendered(true, songId!);
        }
      });
    } else if (!filteredVersions?.length) {
      navigate('/dashboard');

      deleteFolderSong(folderId!, songId!);
      enqueueSnackbar('Song deleted successfully', { variant: 'success' });
    }
  };

  return (
    <>
      {!!versionOnDelete && (
        <DeleteSongVersionDialog
          onClose={() => setVersionOnDelete(null)}
          version={versionOnDelete}
          deleteSongVersion={handleDeleteSongVersion}
          isPending={false}
        />
      )}
      {isUploadNewVersionDialogOpen && (
        <UploadNewSongVersionDialog
          open={isUploadNewVersionDialogOpen}
          rerender={cachedStems =>
            setSoloMutedStems(() =>
              cachedStems?.reduce((map, stem) => map.set(stem.id, { id: stem.id, solo: false, muted: false }), new Map())
            )
          }
          onClose={() => {
            setIsUploadNewVersionDialogOpen(false);
          }}
          folderId={folderId!}
          songId={songId!}
        />
      )}
      {isMobile ? (
        <>
          {isVersionsOpened && (
            <VersionsDialog
              canEdit={canEdit}
              open={isVersionsOpened}
              onClose={() => setIsVersionsOpened(false)}
              songName={song?.name ?? songVersions.find(v => v.id === currentVersion)?.songName ?? 'Enter song name'}
              uploadNewVersion={() => {
                setIsVersionsOpened(false);
                setIsUploadNewVersionDialogOpen(true);
              }}
              deleteVersion={version => {
                setIsVersionsOpened(false);
                setVersionOnDelete(version);
              }}
              changeVersion={handleChangeVersion}
            />
          )}
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => setIsVersionsOpened(true)}
            disableElevation={true}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              background: 'rgba(75, 75, 75, 0.5)',
              height: '28px',
              minWidth: 'unset',
              width: '54px',
              padding: '5px 8px 5px 6px',
              fontSize: '12px',
              lineHeight: '18px'
            }}
          >
            V {songVersions.find(s => s.id === currentVersion)?.versionNumber} <ArrowDown />
          </Button>
        </>
      ) : (
        <>
          <TextField
            size="small"
            sx={{
              width: '57px',
              display: 'block',
              textAlign: 'center',
              '& fieldset': { border: 'none' },
              '& .MuiInputLabel-root': { fontSize: '12px' },
              '& .MuiInputBase-input': {
                fontSize: '12px',
                lineHeight: '18px',
                padding: '5px 6px 5px 8px;'
              }
            }}
            select
            value={String(currentVersion)}
            onChange={e => handleChangeVersion(e.target.value)}
            label=""
            SelectProps={{
              IconComponent: ArrowDown,
              onOpen: () => {
                setIsVersionsOpened(true);
              },
              onClose: () => {
                setIsVersionsOpened(false);
              },
              open: isVersionsOpened,
              renderValue: value => <>V {songVersions.find(version => version.id === value)?.versionNumber}</>,
              MenuProps: {
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                slotProps: {
                  paper: {
                    sx: {
                      ml: 1
                    }
                  }
                }
              }
            }}
          >
            {songVersions?.map(version => (
              <MenuItem value={version.id} key={version.id}>
                <Stack direction="row" justifyContent="space-between" width={1} alignItems="center">
                  <Typography>Version {version.versionNumber}</Typography>
                  {canEdit ? (
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        setIsVersionsOpened(false);
                        setVersionOnDelete(version);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  ) : null}
                </Stack>
              </MenuItem>
            ))}
            {canEdit ? (
              <MenuItem>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={e => {
                    e.stopPropagation();
                    setIsVersionsOpened(false);
                    setIsUploadNewVersionDialogOpen(true);
                  }}
                >
                  Upload new version
                </Button>
              </MenuItem>
            ) : null}
          </TextField>
        </>
      )}
    </>
  );
}
