import DeleteIcon from '@mui/icons-material/DeleteOutline';
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { Folder } from '../../../@types/folders';
import { NotificationType } from '../../../@types/notifications';
import { DialogCloseButton } from '../../../components/DialogCloseButton';
import { DialogUploadSongArea } from '../../../components/DialogUploadSongArea';
import UploadButton from '../../../components/UploadButton';
import notificationService from '../../../services/notificationService';
import songsService from '../../../services/songsService';
import { useFoldersSelector } from '../../../store/selectors/folders.selector';
import { useSongSelector } from '../../../store/selectors/song.selector';
import { useUploadFilesSelector } from '../../../store/selectors/uploadFiles.selector';
import { useUserStore } from '../../../store/user.store';
import { formatDateMDY } from '../../../utils/date';
import { generateId } from '../../../utils/generateId';
import { processUnZipFile } from '../../../utils/useUnzipWorker';

type CreateSongDialogProps = {
  open: boolean;
  onClose: (event?: any, reason?: any) => void;
};

const filter = createFilterOptions<Folder>();

export default function CreateSongDialog({ open, onClose }: CreateSongDialogProps) {
  const [song, setSong] = useState<File | null>(null);
  const [stems, setStems] = useState<File[]>([]);
  const { createNewSong } = useSongSelector();
  const { setUploadData, lastQueue, createParentSong } = useUploadFilesSelector();
  const navigate = useNavigate();
  const { folderId } = useParams();

  const { folders, defaultFolder, createFolder } = useFoldersSelector();
  const user = useUserStore(state => state.user);

  const options = folders.concat(user?.sharedFolders?.map(folder => ({ id: folder.folderId, name: folder.folder.name, songs: [] })) ?? []);

  let timer: string | number | NodeJS.Timeout | undefined;

  const formik = useFormik({
    initialValues: {
      selectedFolder: folderId ?? '',
      description: ''
    },
    onSubmit: async values => {
      const parentSongId = generateId();
      const folderId = formik.values.selectedFolder || defaultFolder?.id || '';
      const songName = song?.name ?? 'DEMO_' + formatDateMDY(new Date());

      const parentSong = await createParentSong(folderId, parentSongId, songName);
      const firstVersion = await songsService.addSongVersion(parentSongId, songName, formik.values.description ?? '');

      firstVersion.songName = songName;

      const stemsIds = stems.map(
        _ =>
          'stem_' +
          generateId() +
          '_' +
          new Date()
            .toISOString()
            .replace(/[-T:.]/g, '_')
            .slice(0, -1)
      );

      createNewSong(
        song
          ? {
              id:
                'song_' +
                generateId() +
                '_' +
                new Date()
                  .toISOString()
                  .replace(/[-T:.]/g, '_')
                  .slice(0, -1),
              mime: song.type,
              name: song.name,
              createdAt: new Date().toString(),
              updatedAt: new Date().toString(),
              uploadedBy: user?.name,
              size: song.size,
              order: 0,
              url: URL.createObjectURL(song)
            }
          : null,
        stems.map((stem, i) => ({
          id: stemsIds[i],
          songParentId: parentSongId,
          mime: stem.type,
          name: stem.name,
          createdAt: new Date().toString(),
          updatedAt: new Date().toString(),
          uploadedBy: user?.name,
          size: stem.size,
          order: i + 1,
          url: URL.createObjectURL(stem)
        })),
        [firstVersion],
        firstVersion.id,
        parentSongId,
        formik.values.description,
        new Date().toString(),
        new Date().toString(),
        user?.name,
        0
      );

      setUploadData(
        song ? { file: song, progress: 0, estimatedTime: 0 } : null,
        stems.map((stem, i) => ({ file: stem, progress: 0, estimatedTime: 0, stemId: stemsIds[i], queueId: lastQueue + 1 })),
        parentSongId,
        folderId,
        true
      );

      notificationService.createNotification(NotificationType.UploadSong, parentSongId, folderId);

      onClose();

      navigate(`/dashboard`);
      timer = setTimeout(() => navigate(`/dashboard/${folderId}/${parentSongId}`), 20);
    }
  });

  const { setFieldValue, errors, touched, handleBlur } = formik;

  useEffect(() => {
    if (folderId) {
      formik.setValues({ selectedFolder: folderId, description: '' });
    }
    return () => clearTimeout(timer);
  }, [folderId]);

  const handleUploadSong = (files: FileList) => {
    setSong(files[0] ?? null);
  };

  const isZipFile = (file: File): boolean => {
    return file.type === 'application/zip' || file.name.endsWith('.zip');
  };

  const handleUploadStems = async (files: FileList) => {
    const newStems: File[] = [];

    if (files.length === 1 && isZipFile(files[0])) {
      const stems = await processUnZipFile(files[0]);
      newStems.push(...stems);
    } else {
      for (let i = 0; i < files.length; i++) {
        newStems.push(files[i]);
      }
    }
    setStems(prevStems => [...prevStems, ...newStems]);
  };

  const handleDeleteSong = () => {
    setSong(null);
  };

  const handleDeleteStem = (stemIndex: number) => {
    setStems(prevStems => prevStems.filter((stem, i) => stemIndex !== i));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <DialogCloseButton onClose={onClose} />
          <DialogTitle>Upload song</DialogTitle>
          <DialogContent dividers sx={{ position: 'relative' }}>
            <Box
              sx={{ backgroundColor: 'rgba(255, 255, 255, 0.12)', width: '1px', height: '100%', top: 0, left: '50%' }}
              position="absolute"
            ></Box>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Grid container spacing={3} direction="column">
                  <Grid item mt={3}>
                    <Autocomplete
                      size="small"
                      fullWidth
                      disablePortal
                      value={options.find(folder => folder.id === formik.values.selectedFolder)}
                      options={options}
                      freeSolo
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        const isExisting = options.some(option => inputValue === option.name);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            id: '',
                            name: inputValue,
                            songs: []
                          });
                        }

                        return filtered;
                      }}
                      getOptionLabel={folder => (typeof folder === 'string' ? folder : folder.name)}
                      onChange={async (event: any, folder) => {
                        if (typeof folder === 'string') {
                        } else {
                          if (folder?.id) {
                            setFieldValue('selectedFolder', folder?.id);
                          } else {
                            if (folder?.name) {
                              const newFolder = await createFolder(folder?.name ?? '');

                              setFieldValue('selectedFolder', newFolder?.id);
                            }
                          }
                        }
                      }}
                      onBlur={handleBlur}
                      renderOption={(props, folder) => {
                        return (
                          <li {...props} key={folder.id}>
                            {!folder.id && '+ New folder'} {folder.name}
                          </li>
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          name="selectedFolder"
                          id="selectedFolder"
                          value={formik.values.selectedFolder}
                          onBlur={handleBlur}
                          label="Select/Create Folder"
                          sx={{
                            '& .MuiFormLabel-root': {
                              fontSize: '14px',
                              color: '#808080'
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Song version description here (optional)"
                      multiline
                      rows={8}
                      fullWidth
                      {...formik.getFieldProps('description')}
                      sx={{
                        '& .MuiFormLabel-root': {
                          fontSize: '14px',
                          color: '#808080'
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} flexGrow={1}>
                <Grid height={1} container direction="column" rowGap={3}>
                  <Grid item>
                    <Typography>File uploading</Typography>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <Stack spacing={1} height={1}>
                      {song ? (
                        <Stack flexDirection="column" spacing={1.25}>
                          <Typography>Related song</Typography>
                          <Stack
                            sx={{ background: 'rgba(44, 44, 44, 0.40)' }}
                            p={1.25}
                            borderRadius={0.5}
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <Stack flexDirection="row" alignItems="center" columnGap={1}>
                              <Icon>
                                <img src={'/assets/song.svg'} height={24} width={24} alt="song" />
                              </Icon>
                              <Typography fontWeight={300} fontSize={14}>
                                {song.name}
                              </Typography>
                            </Stack>
                            <Stack flexDirection="row" alignItems="center" columnGap={1}>
                              <Button
                                onClick={handleDeleteSong}
                                variant="contained"
                                size="small"
                                color="secondary"
                                sx={{ height: '28px', width: '28px', minWidth: 'unset' }}
                              >
                                <DeleteIcon fontSize="small" />
                              </Button>
                            </Stack>
                          </Stack>
                        </Stack>
                      ) : (
                        <DialogUploadSongArea
                          height="50%"
                          title={
                            <Grid container alignItems={'center'} justifyContent={'center'} columnGap={0.5}>
                              <Grid item xs={'auto'}>
                                <Typography textAlign="center" variant="body2">
                                  Drag and drop or upload
                                </Typography>
                              </Grid>
                              <Grid item xs={'auto'}>
                                <Icon>
                                  <img src={'/assets/songIconUploadPage.svg'} height={16} width={16} alt="song" />
                                </Icon>
                              </Grid>
                              <Grid item xs={'auto'}>
                                <Typography fontWeight={'bold'} variant={'body2'}>
                                  Song
                                </Typography>
                              </Grid>
                              <Grid item xs={'auto'}>
                                <Typography variant={'body2'}>here</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography textAlign="center" variant="body2">
                                  (i.e. master, mix bus, instrumental, or rough mix, etc.)
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                          onUpload={handleUploadSong}
                        />
                      )}
                      {!!stems.length ? (
                        <Stack flexDirection="column" spacing={1.25}>
                          <Typography>Related stems</Typography>
                          <SimpleBar style={{ maxHeight: '150px' }}>
                            <Grid container flexDirection="column" rowGap={0.5}>
                              {stems.map((stem, i) => (
                                <Grid item key={stem.name}>
                                  <Stack
                                    sx={{ background: 'rgba(44, 44, 44, 0.40)' }}
                                    p={1.25}
                                    borderRadius={0.5}
                                    flexDirection="row"
                                    justifyContent="space-between"
                                  >
                                    <Stack flexDirection="row" alignItems="center" columnGap={1}>
                                      <Icon>
                                        <img src={'/assets/wave.svg'} height={24} width={24} alt="song" />
                                      </Icon>
                                      <Typography fontWeight={300} fontSize={14}>
                                        {stem.name}
                                      </Typography>
                                    </Stack>
                                    <Stack flexDirection="row" alignItems="center" columnGap={1}>
                                      <Button
                                        onClick={() => handleDeleteStem(i)}
                                        variant="contained"
                                        size="small"
                                        color="secondary"
                                        sx={{ height: '28px', width: '28px', minWidth: 'unset' }}
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </Button>
                                    </Stack>
                                  </Stack>
                                </Grid>
                              ))}
                            </Grid>
                          </SimpleBar>
                          <UploadButton multiple={true} onUpload={handleUploadStems} title={'Upload more stems'} />
                        </Stack>
                      ) : (
                        <DialogUploadSongArea
                          height="50%"
                          multiple={true}
                          title={
                            <Grid container alignItems={'center'} justifyContent={'center'} columnGap={0.5}>
                              <Grid item xs={'auto'}>
                                <Typography textAlign="center" variant="body2">
                                  Drag and drop or upload
                                </Typography>
                              </Grid>
                              <Grid item xs={'auto'}>
                                <Icon>
                                  <img src={'/assets/waveIconUploadPage.svg'} height={24} width={24} alt="stem" />
                                </Icon>
                              </Grid>
                              <Grid item xs={'auto'}>
                                <Typography fontWeight={'bold'} variant={'body2'}>
                                  Stems
                                </Typography>
                              </Grid>
                              <Grid item xs={'auto'}>
                                <Typography variant={'body2'}>here</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography textAlign="center" variant="body2">
                                  (i.e. individual tracks, buses, groups, etc.)
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                          onUpload={handleUploadStems}
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" size="small" variant="contained" color="info">
              Upload
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
