import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/router';
import { useTokenStore } from './store/token.store';
import { useAxiosInterceptors } from './utils/axios';

function App() {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const setToken = useTokenStore(state => state.setToken);
  const setIsTokenLoading = useTokenStore(state => state.setLoading);
  const token = useTokenStore(state => state.token);
  const isTokenLoading = useTokenStore(state => state.isLoading);
  const setFinished = useTokenStore(state => state.setFinished);

  const getToken = async () => {
    setIsTokenLoading(true);

    return await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? ''
      }
    }).then(t => {
      setToken(t);
      setIsTokenLoading(false);
      setFinished(true);
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      getToken();
    } else {
      setFinished(false);
    }
  }, [isAuthenticated]);

  useAxiosInterceptors(token);

  return !isLoading && !isTokenLoading ? <RouterProvider router={router} /> : null; // add suspense or loader for it
}

export default App;
