import { useAuth0 } from '@auth0/auth0-react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Slider,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { NotificationType } from '../@types/notifications';
import { stemToUpload } from '../@types/uploadFile';
// @ts-ignore
import { AudioFile, SoloMutedStems } from '../@types/songs';
import { ReactComponent as Wave } from '../assets/icons/wave.svg';
import { MetadataSidebar } from '../components/MetadataSidebar';
import { StemItem } from '../components/StemItem';
import { UploadArea } from '../components/UploadArea';
import { Playlist } from '../components/multitrack/Playlist';
import TimeScale from '../components/multitrack/Timescale';

import '../components/multitrack/playlist.css';
import useSongAccess from '../hooks/useSongAccess';
import { SongDateUploading } from '../sections/mainSongPage/SongDateUploading';
import { SongDescription } from '../sections/mainSongPage/SongDescription';
import { SongDownload } from '../sections/mainSongPage/SongDownload';
import { SongLinkCopy } from '../sections/mainSongPage/SongLinkCopy';
import { SongMetadata } from '../sections/mainSongPage/SongMetadata';
import { SongName } from '../sections/mainSongPage/SongName';
import { SongPlayer } from '../sections/mainSongPage/SongPlayer';
import { SongSharing } from '../sections/mainSongPage/SongSharing';
import { SongShowButton } from '../sections/mainSongPage/SongShowButton';
import { SongUploadArea } from '../sections/mainSongPage/SongUploadArea';
import { SongVersions } from '../sections/mainSongPage/SongVersions';
import notificationService from '../services/notificationService';
import trackEvent from '../services/trackService';
import { usePlaylistStore } from '../store/players.store';
import { useFoldersSelector } from '../store/selectors/folders.selector';
import { useSongSelector } from '../store/selectors/song.selector';
import { useUploadFilesSelector } from '../store/selectors/uploadFiles.selector';
import { createFileList, getFile, isValidAudioFile, isZipFile, readDirectory } from '../utils/fileUtils';
import { generateId } from '../utils/generateId';
import { processUnZipFile } from '../utils/useUnzipWorker';
import './scroll.css';

export function MainSongPage() {
  const { folderId, songId } = useParams();
  const { canEdit, isOwner, ownerEmail, userId, canView } = useSongAccess(songId);
  const { renameSongInSidebar } = useFoldersSelector();
  const { uploadingSongs } = useUploadFilesSelector();
  const { user } = useAuth0();
  const { addUploadStems, lastQueue } = useUploadFilesSelector();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    songVersions,
    currentVersion,
    song,
    songParentId,
    stems,
    getSongVersions,
    getSongWithStems,
    clearSong,
    clearStems,
    reorderStems,
    createdAt,
    uploadedBy,
    bpm,
    setSong,
    addStems,
    isNewSong,
    setIsNewSong
  } = useSongSelector();
  const { setUploadData } = useUploadFilesSelector();

  const playlistState = usePlaylistStore(state => state.playListStates.find(playlistState => playlistState.versionId === currentVersion));
  const playlistStates = usePlaylistStore(state => state.playListStates);
  let playlist: any;
  let waveSurfer: any;
  let isSongRendered;
  let stemsCountForLoading: number = 0;
  let loadedStemsCount;
  let isStemsRendered: boolean = false;
  let playlistEventEmitter: any;
  if (playlistState) {
    playlist = playlistState.playlist;
    waveSurfer = playlistState.waveSurfer;
    isSongRendered = playlistState.isSongRendered;
    stemsCountForLoading = playlistState.stemsCountForLoading;
    loadedStemsCount = playlistState.loadedStemsCount;
    isStemsRendered = playlistState.isStemsRendered;
    playlistEventEmitter = playlistState.eventEmitter;
  }

  const handleIsSongRendered = usePlaylistStore(state => state.handleIsSongRendered);
  const handleIsStemsRendered = usePlaylistStore(state => state.handleIsStemsRendered);
  const setStemsCountForLoading = usePlaylistStore(state => state.setStemsCountForLoading);
  const incrLoadedStemsCount = usePlaylistStore(state => state.incrLoadedStemsCount);
  const clearLoadedStemsCount = usePlaylistStore(state => state.clearLoadedStemsCount);
  const init = usePlaylistStore(state => state.init);
  const [zoomLevel, setZoomLevel] = useState<number>(0);
  const [soloMutedStems, setSoloMutedStems] = useState<Map<string, SoloMutedStems>>(new Map());
  const [isDraggingStems, setIsDraggingStems] = useState(false);

  const [isSongPlaying, setIsSongPlaying] = useState(false);
  const [isStemsPlaying, setIsStemsPlaying] = useState(false);

  const multitrackContainerRef = useRef<HTMLDivElement | null>(null);

  const [isReorderingStems, setIsReorderingStems] = useState(false);
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const [isSongShown, setIsSongShown] = useState(false);
  const [height, setHeight] = useState('0px');

  const [handleRenameSong, setHandleRenameSong] = useState<(() => void) | null>(null);

  const emitAddSong = (stems: any): void => {
    playlist.load(stems, () => incrLoadedStemsCount(currentVersion!));
  };
  const playlistEventEmitterRef = useRef(playlistEventEmitter);

  useEffect(() => {
    playlistEventEmitterRef.current = playlistEventEmitter;
  }, [playlistEventEmitter]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === ' ') {
        event.preventDefault();
        handleButtonClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (multitrackContainerRef.current) {
      const newHeight = `calc(100svh - 24px - ${multitrackContainerRef.current.offsetTop}px)`;
      setHeight(newHeight);
    }
  }, [isSongShown]);

  useEffect(() => {
    if (playlist) {
      playlist.renderTimeScale = function () {
        const controlWidth = this.controls.show ? this.controls.width : 0;
        const timeScale = new TimeScale(
          this.duration,
          this.scrollLeft,
          this.samplesPerPixel,
          this.sampleRate,
          this.colors,
          controlWidth,
          bpm || 0
        );
        return timeScale.render();
      };
      playlist.drawRequest();
    }
  }, [playlist]);

  useEffect(() => {
    if (playlist) {
      const zoomIndex = playlist.zoomLevels.length - zoomLevel - 1;
      if (playlist.zoomIndex !== zoomIndex) {
        playlist.setZoomIndex(zoomIndex);
        playlist.setZoom(playlist.zoomLevels[zoomIndex]);
        playlist.drawRequest();
      }
    }
  }, [zoomLevel, playlist]);

  useEffect(() => {
    playlistStates.forEach(playlistState => playlistState?.eventEmitter.emit('pause'));

    if (isNewSong) {
      setSoloMutedStems(new Map());
      setIsSongPlaying(false);
      setIsStemsPlaying(false);
      init(currentVersion!);
      setStemsCountForLoading(stems.length, currentVersion!);
      setIsNewSong(false);
      rerenderWithCache(stems);
    } else {
      user && trackEvent('song_view', { songId: songId!, songName: song?.name ?? '', folderId: folderId ?? '' });
      clearSong();
      clearStems();
      setSoloMutedStems(new Map());
      setIsSongPlaying(false);
      setIsStemsPlaying(false);
      const uploadState = uploadingSongs.find(uploadSong => uploadSong.songId === songId);
      const getDataAndRender = async () => {
        const currentVersion = await getSongVersions(songId!);
        const playListState = init(currentVersion!);
        await getSongWithStems(uploadState?.stems, uploadState?.song).then(files => {
          if (!files.song) {
            handleIsSongRendered(true, currentVersion!);
          }
          if (files) {
            if (playListState?.soloMutedStems) {
              setSoloMutedStems(playListState?.soloMutedStems);
            } else {
              setSoloMutedStems(() =>
                files?.stems?.reduce((map, stem) => map.set(stem.id, { id: stem.id, solo: false, muted: false }), new Map())
              );
            }
          }
          setStemsCountForLoading(files.stems.length, currentVersion!);

          if (!files.stems.length) {
            handleIsStemsRendered(true, currentVersion!);
          }
        });
      };

      getDataAndRender();
    }
    return () => {};
  }, [songId, folderId]);

  useEffect(() => {
    if (song) {
      setIsSongShown(true);
    }
  }, [song]);

  const rerenderWithCache = (cachedStems: AudioFile[]) => {
    setSoloMutedStems(() => cachedStems?.reduce((map, stem) => map.set(stem.id, { id: stem.id, solo: false, muted: false }), new Map()));
  };
  const handleZoomSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
    setZoomLevel(value as number);
  };

  const handleStemsReorder = async (result: DropResult) => {
    await reorderStems(result);
    if (playlist) {
      const sourceStem = playlist.tracks[result.source.index];
      playlist.tracks.splice(result.source.index, 1);
      playlist.tracks.splice(result.destination?.index ?? 0, 0, sourceStem);
      playlist.drawRequest();
    }
    setIsReorderingStems(false);
  };

  const playListStopPlayingHandler = () => {
    setIsStemsPlaying(false);
  };

  const uploadSong = async (file: File) => {
    setSong({
      id:
        'song_' +
        generateId() +
        '_' +
        new Date()
          .toISOString()
          .replace(/[-T:.]/g, '_')
          .slice(0, -1),
      mime: file.type,
      name: file.name,
      size: file.size,
      order: 0,
      url: URL.createObjectURL(file)
    });

    if (currentVersion === songVersions.sort((a, b) => a.versionNumber - b.versionNumber).at(songVersions.length - 1)?.id) {
      renameSongInSidebar(folderId!, songId!, file.name);
    }

    setUploadData(
      {
        file,
        progress: 0,
        estimatedTime: 0
      },
      [],
      songId ?? '',
      folderId!
    );
  };

  const uploadStems = async (files: FileList) => {
    const newStems: stemToUpload[] = [];

    for (let i = 0; i < files.length; i++) {
      if (isZipFile(files[i])) {
        const stems = await processUnZipFile(files[i]);
        for (let stem of stems) {
          newStems.push({
            file: stem,
            progress: 0,
            estimatedTime: 0,
            stemId:
              'stem_' +
              generateId() +
              '_' +
              new Date()
                .toISOString()
                .replace(/[-T:.]/g, '_')
                .slice(0, -1),
            queueId: lastQueue + 1
          });
        }
      } else if (isValidAudioFile(files[i])) {
        newStems.push({
          file: files[i],
          progress: 0,
          estimatedTime: 0,
          stemId:
            'stem_' +
            generateId() +
            '_' +
            new Date()
              .toISOString()
              .replace(/[-T:.]/g, '_')
              .slice(0, -1),
          queueId: lastQueue + 1
        });
      }
    }

    addUploadStems(newStems, songId!, folderId ?? '');

    const cachedStems: AudioFile[] = newStems.map((stem, i) => ({
      id: stem.stemId,
      mime: stem.file.type,
      name: stem.file.name,
      createdAt: new Date().toString(),
      uploadedBy: user?.name,
      size: stem.file.size,
      order: i + 1,
      url: URL.createObjectURL(stem.file)
    }));

    addStems(cachedStems);
    if (isStemsRendered) {
      clearLoadedStemsCount(currentVersion!);
      setStemsCountForLoading(cachedStems.length, currentVersion!);
    } else {
      setStemsCountForLoading(cachedStems.length + stemsCountForLoading, currentVersion!);
    }
    if (emitAddSong)
      emitAddSong(
        cachedStems.map(stem => {
          return { src: stem.url, name: stem.name };
        })
      );
  };

  const handleButtonClick = () => {
    setIsSongPlaying(false);
    waveSurfer?.pause();

    setIsStemsPlaying(isPlaying => {
      if (isPlaying) {
        playlistEventEmitterRef.current?.emit('pause');
      } else {
        playlistEventEmitterRef.current?.emit('play');
        !!user && notificationService.createNotification(NotificationType.ListenSong, songId!);
      }
      return !isPlaying;
    });
  };

  return !isMobile ? (
    <Grid container height={1} direction={'row'} columnSpacing={2}>
      <Grid item xs={isSidebarOpened ? 8.8 : 12}>
        <Stack direction="column" height={1} sx={{ borderRadius: '8px' }}>
          <Stack
            direction="column"
            rowGap={1.5}
            p={3}
            pb={0}
            sx={{
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              background: 'linear-gradient(180deg, rgba(0, 142, 243, 0.3) 0%, rgba(0, 142, 243, 0.54) 0.02%, rgba(18, 18, 18, 0) 110%)'
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <SongName canEdit={canEdit || isOwner} setHandleRename={setHandleRenameSong} />
              <Stack direction="row" alignItems="flex-start" sx={{ gap: '11px' }}>
                {canEdit || isOwner ? <SongSharing isOwner={isOwner} ownerEmail={ownerEmail} disabled={false} /> : null}
                <SongLinkCopy disabled={false} />
                {!canView ? <SongDownload disabled={false} /> : null}
              </Stack>
            </Stack>
            <Stack direction="row" gap={'8px'}>
              <SongVersions
                canEdit={isOwner || canEdit}
                turnOffSong={() => setIsSongPlaying(false)}
                turnOffPlaylist={() => setIsStemsPlaying(false)}
                setSoloMutedStems={setSoloMutedStems}
              />

              <SongMetadata isSidebarOpened={isSidebarOpened} toggleSidebar={() => setIsSidebarOpened(prevState => !prevState)} />
              <SongShowButton isSongShown={isSongShown} toggleSongShown={() => setIsSongShown(prev => !prev)} song={song} />
            </Stack>
            <Grid container alignItems={'center'}>
              <Grid item xs={5} paddingBottom={2}>
                <Stack direction="column">
                  <SongDescription canEdit={canEdit || isOwner} />
                  {!!createdAt && !!uploadedBy && <SongDateUploading uploadedBy={uploadedBy} createdAt={createdAt} />}
                </Stack>
              </Grid>
              <Grid item xs={7} pl={1}>
                {isSongShown &&
                  (song?.url ? (
                    <SongPlayer
                      waveSurfer={waveSurfer}
                      playlistEventEmitter={playlistEventEmitter}
                      setIsSongPlaying={setIsSongPlaying}
                      setIsStemsPlaying={setIsStemsPlaying}
                      isStemsPlaying={isStemsPlaying}
                      isSongPlaying={isSongPlaying}
                    />
                  ) : isOwner || canEdit ? (
                    <SongUploadArea uploadSong={file => uploadSong(file)} />
                  ) : null)}
                <Grid container justifyContent="flex-end" alignItems={'flex-end'}>
                  <Grid item>
                    <IconButton size="small" onClick={() => setZoomLevel(zoomLevel - 1)} disabled={zoomLevel <= 0}>
                      <ZoomOutIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Slider
                      min={0}
                      max={12}
                      size="small"
                      aria-label="Zoom"
                      value={zoomLevel}
                      onChange={handleZoomSliderChange}
                      sx={{
                        width: 70,
                        '& .MuiSlider-thumb': {
                          backgroundColor: 'white'
                        },
                        '& .MuiSlider-rail': {
                          backgroundColor: '#131313'
                        }
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <IconButton size="small" onClick={() => setZoomLevel(zoomLevel + 1)} disabled={zoomLevel >= 12}>
                      <ZoomInIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
          <Box
            display={'flex'}
            position={'relative'}
            flexGrow={1}
            height={1}
            ref={multitrackContainerRef}
            sx={{ backgroundColor: '#151515', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
          >
            {(isDraggingStems || (stems.length === 0 && songParentId)) && (
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  zIndex: 10
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    background: 'url(/assets/backgroundMultitrack.png) no-repeat',
                    objectFit: 'cover',
                    backgroundSize: 'cover'
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: 'DM Sans, sans-serif',
                      display: 'flex',
                      cursor: 'copy',
                      background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.85) 88.47%)',
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      userSelect: 'none',
                      color: 'white',
                      fontSize: '28px',
                      borderRadius: '0px 0px 8px 8px'
                    }}
                  >
                    <Box
                      component="label"
                      onDrop={async (e: React.DragEvent<HTMLDivElement>) => {
                        if (!isDraggingStems) return;
                        e.preventDefault();

                        const items = e.dataTransfer.items;
                        let files: File[] = [];

                        const filePromises = Array.from(items).map(async item => {
                          const entry = item.webkitGetAsEntry();

                          if (entry) {
                            if (entry.isDirectory) {
                              await readDirectory(entry as FileSystemDirectoryEntry, files);
                            } else if (entry.isFile) {
                              const file = await getFile(entry as FileSystemFileEntry);
                              files.push(file);
                            }
                          }
                        });

                        setIsDraggingStems(false);

                        await Promise.all(filePromises);

                        if (files.length > 0) {
                          await uploadStems(createFileList(files));
                        }
                      }}
                      onDragEnter={() => {
                        if (!canEdit) return;

                        if (!isReorderingStems) setIsDraggingStems(true);
                      }}
                      onDragLeave={() => {
                        setIsDraggingStems(false);
                      }}
                      onDragEnd={() => {
                        setIsDraggingStems(false);
                      }}
                      onDragOver={e => {
                        e.preventDefault();
                      }}
                      sx={{ position: 'absolute', width: '100%', height: '100%', cursor: 'copy', zIndex: 20 }}
                    >
                      <UploadArea onUpload={uploadStems} multiple={true} disabled={!canEdit && !isOwner} />
                    </Box>
                    <Stack sx={{ textAlign: 'center' }} direction="column">
                      <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="h4">
                        Drag and drop or upload {<Wave />} Stems here
                      </Typography>
                      <Typography variant="h5">(i.e. individual tracks, buses, groups, etc.)</Typography>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            )}
            <SimpleBar
              onDragEnter={() => {
                if (!canEdit && !isOwner) return;

                setIsDraggingStems(true);
              }}
              style={{
                maxHeight: height,
                height: `calc(100svh - 24px - ${multitrackContainerRef.current?.offsetTop}px)`,
                width: '100%',
                borderTop: '1px solid #494949',
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              <Grid container direction="row" flexGrow={1} height={1}>
                <Grid item xs={isSidebarOpened ? 3 : 2.4}>
                  {/*Move buttons to another component*/}
                  <Stack
                    direction="row"
                    pl={3}
                    pr={2}
                    justifyContent="space-between"
                    sx={{
                      position: 'sticky',
                      top: '0',
                      background: '#151515',
                      borderRight: '1px solid #494949',
                      borderBottom: '1px solid #494949',
                      zIndex: '999',
                      paddingTop: '17px',
                      paddingBottom: '16px'
                    }}
                  >
                    {' '}
                    <Tooltip title={'Play stems'}>
                      <Button
                        size="small"
                        sx={{
                          minWidth: 'unset',
                          width: '28px',
                          height: '28px'
                        }}
                        disabled={!stems?.length}
                        variant="contained"
                        color="info"
                        onClick={handleButtonClick}
                      >
                        {isStemsPlaying ? <PauseIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" sx={{ margin: 0 }} />}
                      </Button>
                    </Tooltip>
                    {stemsCountForLoading && !isStemsRendered && (
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        fontWeight={400}
                        fontSize={14}
                        color="text.primary"
                        fontFamily={'DM Sans, sans-serif'}
                      >
                        <CircularProgress size={14} sx={{ mr: 1 }} color="inherit" />
                        Loading {loadedStemsCount}/{stemsCountForLoading}
                      </Stack>
                    )}
                    {isOwner || canEdit ? (
                      <Button
                        size="small"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          minWidth: 'unset',
                          width: '28px',
                          height: '28px'
                        }}
                        variant="contained"
                        color="secondary"
                        component="label"
                      >
                        <UploadArea onUpload={uploadStems} multiple={true} />

                        <FileUploadOutlinedIcon fontSize="small" sx={{ width: '16px', height: '16px' }} />
                      </Button>
                    ) : null}
                  </Stack>
                  <DragDropContext onDragStart={() => setIsReorderingStems(true)} onDragEnd={handleStemsReorder}>
                    <Droppable droppableId="droppable" direction="vertical">
                      {({ innerRef, droppableProps, placeholder }) => (
                        <Box ref={innerRef} {...droppableProps}>
                          {stems?.map((stem, i) => (
                            <Draggable isDragDisabled={!currentVersion} draggableId={stem.id} index={i} key={stem.id}>
                              {({ innerRef, draggableProps, dragHandleProps }) => (
                                <StemItem
                                  isReordering={isReorderingStems}
                                  innerRef={innerRef}
                                  draggableProps={draggableProps}
                                  dragHandleProps={dragHandleProps}
                                  key={stem.id}
                                  stem={stem}
                                  isSolo={soloMutedStems?.get(stem.id)?.solo}
                                  isMuted={soloMutedStems?.get(stem.id)?.muted}
                                  canEdit={canEdit}
                                  isOwner={isOwner}
                                  canView={canView}
                                  playlistEventEmitter={playlistEventEmitter}
                                  playlist={playlist}
                                  setSoloMuted={setSoloMutedStems}
                                  soloMutedStems={soloMutedStems}
                                  index={i}
                                />
                              )}
                            </Draggable>
                          ))}
                          {placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Grid>

                <Grid item xs={isSidebarOpened ? 9 : 9.6} position="relative">
                  {!isNewSong && currentVersion && !!stems.length && (
                    <Playlist
                      soloMutedStems={soloMutedStems}
                      versionId={currentVersion!}
                      stems={stems.map(stem => ({ name: stem.name, src: stem.url }))}
                      onStopPlaying={playListStopPlayingHandler}
                    />
                  )}
                </Grid>
              </Grid>
            </SimpleBar>
          </Box>
        </Stack>
      </Grid>
      {isSidebarOpened && (
        <Grid item xs={3.2} height={1}>
          <MetadataSidebar
            playlist={playlist}
            isSidebarOpened={isSidebarOpened}
            closeSidebar={() => setIsSidebarOpened(false)}
            canEdit={isOwner || canEdit}
          />
        </Grid>
      )}
    </Grid>
  ) : (
    <Grid container height={1} direction={'row'} columnSpacing={2}>
      <Grid item xs={12}>
        <Stack direction="column" height={1} sx={{ borderRadius: '8px' }}>
          <Stack
            direction="column"
            rowGap={2}
            pt={'21px'}
            pb={3}
            sx={{
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              background: 'linear-gradient(180deg, rgba(0, 142, 243, 0.3) 0%, rgba(0, 142, 243, 0.54) 0.02%, rgba(18, 18, 18, 0) 110%)'
            }}
          >
            <Stack direction="row">
              {stemsCountForLoading && !isStemsRendered ? (
                <Stack
                  paddingLeft="16px"
                  direction="row"
                  fontFamily={'DM Sans, sans-serif'}
                  alignItems="center"
                  fontWeight={400}
                  fontSize={14}
                  color="text.primary"
                >
                  <CircularProgress size={14} sx={{ mr: 1 }} color="inherit" />
                  Loading {loadedStemsCount}/{stemsCountForLoading}
                </Stack>
              ) : (
                ''
              )}
              <Stack flexGrow={1} direction="row" justifyContent="flex-end" px={2} pb={'4px'}>
                <Stack direction="row" alignItems="flex-start" columnGap={'11px'}>
                  {canEdit || isOwner ? <SongSharing isOwner={isOwner} ownerEmail={ownerEmail} disabled={false} /> : null}
                  <SongLinkCopy disabled={false} />
                  {!canView ? <SongDownload disabled={false} /> : null}
                </Stack>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="column" rowGap={1} px={2}>
              <SongName canEdit={canEdit || isOwner} setHandleRename={setHandleRenameSong} />
              {!!createdAt && !!uploadedBy && <SongDateUploading uploadedBy={uploadedBy} createdAt={createdAt} />}
              <SongDescription canEdit={canEdit || isOwner} />
            </Stack>

            <Stack direction="row" px={2} pt={'4px'} gap={'11px'}>
              <SongVersions
                canEdit={isOwner || canEdit}
                turnOffSong={() => setIsSongPlaying(false)}
                turnOffPlaylist={() => setIsStemsPlaying(false)}
                setSoloMutedStems={setSoloMutedStems}
              />
              <SongMetadata isSidebarOpened={isSidebarOpened} toggleSidebar={() => setIsSidebarOpened(prevState => !prevState)} />
              <SongShowButton isSongShown={isSongShown} toggleSongShown={() => setIsSongShown(prev => !prev)} song={song} />
            </Stack>
            {isSongShown && (
              <Box px={2} mt={1}>
                {song?.url ? (
                  <SongPlayer
                    waveSurfer={waveSurfer}
                    playlistEventEmitter={playlistEventEmitter}
                    setIsSongPlaying={setIsSongPlaying}
                    setIsStemsPlaying={setIsStemsPlaying}
                    isStemsPlaying={isStemsPlaying}
                    isSongPlaying={isSongPlaying}
                  />
                ) : isOwner || canEdit ? (
                  <SongUploadArea uploadSong={file => uploadSong(file)} />
                ) : null}
              </Box>
            )}
          </Stack>
          <Box
            display={'flex'}
            position={'relative'}
            flexGrow={1}
            height={1}
            ref={multitrackContainerRef}
            sx={{ backgroundColor: '#151515' }}
          >
            <SimpleBar
              style={{
                maxHeight: height,
                height: `calc(100svh - 24px - ${multitrackContainerRef.current?.offsetTop ?? 0}px)`,
                width: '100%',
                borderTop: '1px solid #494949',
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              <Grid container direction="row" flexGrow={1} height={1}>
                <Grid item xs={5}>
                  {/*Move buttons to another component*/}
                  <Stack
                    direction="row"
                    pl={2}
                    pr={2}
                    justifyContent="space-between"
                    sx={{
                      position: 'sticky',
                      top: '0',
                      background: 'black',
                      borderRight: '1px solid #494949',
                      borderBottom: '1px solid #494949',
                      zIndex: '999',
                      paddingTop: '17px',
                      paddingBottom: '16px'
                    }}
                  >
                    <Button
                      size="small"
                      disabled={!stems?.length}
                      variant="contained"
                      color="info"
                      sx={{ height: '40px', width: '40px', minWidth: 'unset', boxShadow: 'none' }}
                      onClick={handleButtonClick}
                    >
                      {isStemsPlaying ? (
                        <PauseIcon fontSize="small" sx={{ margin: 0 }} />
                      ) : (
                        <PlayArrowIcon fontSize="small" sx={{ margin: 0 }} />
                      )}
                    </Button>
                    {isOwner || canEdit ? (
                      <Button
                        sx={{ height: '40px', width: '40px', minWidth: 'unset', boxShadow: 'none' }}
                        size="small"
                        variant="contained"
                        color="secondary"
                        component="label"
                      >
                        <UploadArea onUpload={uploadStems} multiple={true} />
                        <FileUploadOutlinedIcon fontSize="small" />
                      </Button>
                    ) : null}
                  </Stack>
                  <DragDropContext onDragStart={() => setIsReorderingStems(true)} onDragEnd={handleStemsReorder}>
                    <Droppable droppableId="droppable" direction="vertical">
                      {({ innerRef, droppableProps, placeholder }) => (
                        <Box ref={innerRef} {...droppableProps}>
                          {stems?.map((stem, i) => (
                            <Draggable isDragDisabled={!currentVersion} draggableId={stem.id} index={i} key={stem.id}>
                              {({ innerRef, draggableProps, dragHandleProps }) => (
                                <StemItem
                                  isReordering={isReorderingStems}
                                  innerRef={innerRef}
                                  draggableProps={draggableProps}
                                  dragHandleProps={dragHandleProps}
                                  key={stem.id}
                                  stem={stem}
                                  isSolo={soloMutedStems?.get(stem.id)?.solo}
                                  isMuted={soloMutedStems?.get(stem.id)?.muted}
                                  canEdit={canEdit}
                                  isOwner={isOwner}
                                  canView={canView}
                                  playlistEventEmitter={playlistEventEmitter}
                                  playlist={playlist}
                                  setSoloMuted={setSoloMutedStems}
                                  soloMutedStems={soloMutedStems}
                                  index={i}
                                />
                              )}
                            </Draggable>
                          ))}
                          {placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Grid>

                <Grid item xs={7} position="relative">
                  {!isNewSong && currentVersion && !!stems.length && (
                    <Playlist
                      soloMutedStems={soloMutedStems}
                      versionId={currentVersion!}
                      stems={stems.map(stem => ({ name: stem.name, src: stem.url }))}
                      onStopPlaying={playListStopPlayingHandler}
                    />
                  )}
                </Grid>
              </Grid>
            </SimpleBar>
          </Box>
        </Stack>
      </Grid>
      {isSidebarOpened && (
        <MetadataSidebar
          playlist={playlist}
          isSidebarOpened={isSidebarOpened}
          closeSidebar={() => setIsSidebarOpened(false)}
          canEdit={isOwner || canEdit}
        />
      )}
    </Grid>
  );
}
