import { Auth0ProviderOptions } from '@auth0/auth0-react';
import authService from './services/authService';

export const apiUrl: string = `${process.env.REACT_APP_API_URl}/api` ?? '';

export const auth0ApiCredentials = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  clientId: process.env.REACT_APP_AUTH0_CLIENTID ?? '',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? ''
};

export const auth0Config: Auth0ProviderOptions = {
  domain: auth0ApiCredentials.domain,
  clientId: auth0ApiCredentials.clientId,
  sessionCheckExpiryDays: 31,
  onRedirectCallback: async (appState, user) => {
    await authService.createUser(user?.sub!);
  },
  cacheLocation: 'localstorage',
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: auth0ApiCredentials.audience
  }
};
