import axios from '../utils/axios';

const createCheckoutSession = async (priceId: string): Promise<string> => {
  return await axios.post<string>('payment/create-checkout-session', { priceId }).then(res => res.data);
};

const cancelSubRenewal = async () => {
  await axios.post('payment/cancel-sub-renewal');
};

const changePlan = async (priceId: string) => {
  await axios.post('payment/change-plan', { priceId });
};

const getUserPlan = async (): Promise<
  { plan: string; period: 'Yearly' | 'Monthly'; expiresAt: string; isCancelled: boolean } | undefined
> => {
  return axios.get('payment/get-user-plan').then(res => res.data);
};

const paymentService = { createCheckoutSession, cancelSubRenewal, changePlan, getUserPlan };

export default paymentService;
