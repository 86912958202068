import axios from "../utils/axios";

const trackEvent = async (
    name: string, props: any
  ): Promise<void> => {
    await axios
      .post(`track`, { name, props })
      .then(res => res.data);
  };

  export default trackEvent