import LinkIcon from '@mui/icons-material/Link';
import PersonIcon from '@mui/icons-material/Person';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { AccessType, SharedUserInfo, SharedUserRoles } from '../@types/sharing';
import { useFoldersSelector } from '../store/selectors/folders.selector';
import { useUserStore } from '../store/user.store';
import axios from '../utils/axios';
import { errorMessage } from '../utils/errorMessage';
import { ValidationPatterns } from '../utils/validationPatterns';
import { DialogCloseButton } from './DialogCloseButton';
import DoneIcon from '@mui/icons-material/Done';
import sharingService from '../services/sharingService';

type SharingDialogProps = {
  folderId?: string;
  sharedFolderUsers?: SharedUserInfo[];
  onClose: () => void;
  open: boolean;
  isOwner: boolean;
  folderName: string;
  ownerEmail?: string;
};

export default function SharingDialog({ sharedFolderUsers, folderId, onClose, open, isOwner, folderName, ownerEmail }: SharingDialogProps) {
  const getUser = useUserStore(state => state.getUser);
  const { shareFolderByInvite, deleteFolderSharedUser } = useFoldersSelector();
  const [showPopup, setShowPopup] = useState(false);
  const [option, setOption] = useState<string>('invited');
  const [accessOption, setAccessOption] = useState('VIEWER');

  const handleChange = async (event: SelectChangeEvent) => {
    await axios.post(`/folders/share-status/${folderId}`, {
      isPublic: event.target.value === 'anyone',
      accessType: event.target.value === 'anyone' ? 'VIEWER' : null
    });

    setOption(event.target.value as string);
    setAccessOption(event.target.value === 'anyone' ? 'VIEWER' : '');
  };

  const handleAccessChange = async (event: SelectChangeEvent) => {
    await axios.post(`/folders/share-status/${folderId}`, {
      isPublic: option === 'anyone',
      accessType: event.target.value
    });

    setAccessOption(event.target.value as string);
  };

  useEffect(() => {
    getUser();

    axios.get(`/folders/get-share-status/${folderId}`).then(res => {
      setOption(res.data.isPublic ? 'anyone' : 'invited');
      setAccessOption(res.data.accessType ?? '');
    });
  }, []);

  const handleSetAccessType = async (sharedUserEmail: string, accessType: string) => {
    await shareFolderByInvite({
      email: sharedUserEmail,
      folderId: folderId ?? '',
      type: accessType,
      folderName
    });

    formik.resetForm();
  };

  const validationSchema = Yup.object({
    email: Yup.string().required('Email is required').matches(ValidationPatterns.email, 'Please enter a valid email'),
    accessType: Yup.string().required().oneOf(Object.values(SharedUserRoles), 'Invalid access type')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      accessType: SharedUserRoles.Viewer
    },
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      handleSetAccessType(values.email, values.accessType);

      formikHelpers.resetForm();
    }
  });

  const { touched, errors, handleSubmit, getFieldProps } = formik;

  const handleCopySharingLink = async () => {
    const accessType = accessOption === 'view' ? SharedUserRoles.Viewer : SharedUserRoles.Downloader;
    const isFolderSharing = `isfs=${true}`;

    const urlPath = option === 'invited' ? `/share/folder/${folderId}/${accessType}` : `/dashboard/public/${folderId}?${isFolderSharing}`;

    const urlForSharing = document.location.origin + urlPath;

    await navigator.clipboard.writeText(urlForSharing);
    setShowPopup(true);

    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  };

  const [emails, setEmails] = useState<string[]>([]);

  useEffect(() => {
    sharingService.getSharedUsersAutofill().then(data => setEmails(data.emails));
  }, []);

  const excludeEmails: string[] = sharedFolderUsers?.map(sharedUser => sharedUser.email) ?? [];

  const filteredEmails = emails.filter(email => !excludeEmails.includes(email));

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <DialogCloseButton onClose={onClose} />
          <DialogTitle>Sharing {folderId && 'folder'}</DialogTitle>
          <DialogContent dividers>
            <Grid container alignItems="center" columnSpacing={1} mb={3}>
              <Grid item xs={10} display={'flex'}>
                <Autocomplete
                  fullWidth
                  options={filteredEmails}
                  clearOnBlur={false}
                  inputValue={formik.values.email}
                  value={formik.values.email}
                  onChange={(event, value, reason) => {
                    formik.setFieldValue('email', reason === 'clear' ? '' : value);
                  }}
                  renderInput={params => (
                    <TextField
                      margin="dense"
                      {...params}
                      placeholder="Email"
                      size="small"
                      autoComplete="off"
                      variant="standard"
                      fullWidth
                      {...getFieldProps('email')}
                      {...errorMessage(touched.email, errors.email)}
                    />
                  )}
                />
                <Select
                  {...getFieldProps('accessType')}
                  disabled={!isOwner}
                  sx={{
                    color: '#ABABAB',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      border: 0
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 0
                    }
                  }}
                >
                  <MenuItem value={SharedUserRoles.Viewer}>{AccessType.CanView}</MenuItem>
                  <MenuItem value={SharedUserRoles.Downloader}>{AccessType.CanDownload}</MenuItem>
                  <MenuItem value={SharedUserRoles.Editor}>{AccessType.CanEdit}</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={2}>
                <Button sx={{ height: '40px' }} type="submit" size="medium" color="warning" variant="contained">
                  Invite
                </Button>
              </Grid>
            </Grid>
            <Grid container flexDirection="column" rowGap={1}>
              <Grid item sx={{ background: 'rgba(139, 139, 139, 0.30)' }} px={1.5} py={1.25} borderRadius={0.5}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Stack flexDirection="row" alignItems="center">
                    <PersonIcon />
                    <Typography ml={1}>{ownerEmail}</Typography>
                  </Stack>
                  <Typography color="#999999">Owner</Typography>
                </Stack>
              </Grid>
              {sharedFolderUsers?.map(sharedUser => (
                <Grid item key={sharedUser.email} sx={{ background: 'rgba(139, 139, 139, 0.30)' }} px={1.5} py={1.25} borderRadius={0.5}>
                  <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Stack flexDirection="row" alignItems="center">
                      <PersonIcon />
                      <Typography ml={1}>{sharedUser.email}</Typography>
                    </Stack>
                    <Select
                      MenuProps={{ PaperProps: { sx: { width: '175px' } } }}
                      size="small"
                      defaultValue={sharedUser.accessType}
                      disabled={!isOwner}
                      renderValue={value => {
                        if (SharedUserRoles.Viewer === value) {
                          return AccessType.CanView;
                        }
                        if (SharedUserRoles.Editor === value) {
                          return AccessType.CanEdit;
                        }
                        if (SharedUserRoles.Downloader === value) {
                          return AccessType.CanDownload;
                        }
                      }}
                      onChange={async e => {
                        if (e.target.value === 'delete') {
                          await deleteFolderSharedUser(sharedUser.email, folderId ?? '');
                        } else {
                          //change for folder
                          await handleSetAccessType(sharedUser.email, e.target.value);
                        }
                      }}
                      SelectDisplayProps={{ style: { padding: 0, paddingRight: 32 } }}
                      sx={{
                        p: 0,
                        color: '#ABABAB',
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                          border: 0
                        },
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: 0
                        }
                      }}
                    >
                      <MenuItem value={SharedUserRoles.Viewer}>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                          <Typography>{AccessType.CanView}</Typography>
                          {sharedUser.accessType === SharedUserRoles.Viewer && <DoneIcon fontSize={'small'} />}
                        </Stack>
                      </MenuItem>
                      <MenuItem value={SharedUserRoles.Downloader}>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                          <Typography>{AccessType.CanDownload}</Typography>
                          {sharedUser.accessType === SharedUserRoles.Downloader && <DoneIcon fontSize={'small'} />}
                        </Stack>
                      </MenuItem>
                      <MenuItem value={SharedUserRoles.Editor}>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                          <Typography>{AccessType.CanEdit}</Typography>
                          {sharedUser.accessType === SharedUserRoles.Editor && <DoneIcon fontSize={'small'} />}
                        </Stack>
                      </MenuItem>
                      <MenuItem value="delete" sx={{ color: '#F53C3C' }}>
                        Remove
                      </MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </DialogContent>

          <DialogContent
            dividers
            sx={{
              '&.MuiDialogContent-dividers': {
                borderTop: 'none !important',
                display: 'flex',
                justifyContent: 'space-between'
              }
            }}
          >
            <Select
              onChange={handleChange}
              value={option}
              sx={{
                color: '#ABABAB',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  border: 0
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 0
                },
                '& .MuiSelect-select': {
                  py: '0 !important'
                }
              }}
            >
              <MenuItem value={'invited'}>Only invited can access</MenuItem>
              <MenuItem value={'anyone'}>Anyone with the link</MenuItem>
            </Select>
            {option !== 'invited' && (
              <Select
                defaultValue={accessOption}
                onChange={handleAccessChange}
                sx={{
                  color: '#ABABAB',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    border: 0
                  },
                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0
                  },
                  '& .MuiSelect-select': {
                    py: '0 !important'
                  }
                }}
              >
                <MenuItem value={'VIEWER'}>Can view</MenuItem>
                <MenuItem value={'CO_OWNER'}>Can download</MenuItem>
              </Select>
            )}
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'flex-start' }}>
            <Button onClick={handleCopySharingLink} size="small" color="secondary" variant="contained">
              <LinkIcon sx={{ marginRight: '4px' }} />
              {showPopup ? 'Link copied!' : 'Copy link'}
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
