import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import { UploadArea } from './UploadArea';

type DialogUploadSongAreaProps = {
  onUpload: (files: FileList) => void;
  title: ReactNode;
  multiple?: boolean;
  height: string;
  disabled?: boolean;
  isLightBackground?: boolean;
};

export function DialogUploadSongArea({
  title,
  multiple = false,
  onUpload,
  height,
  disabled,
  isLightBackground = true
}: DialogUploadSongAreaProps) {
  return (
    <Box
      onDrop={(e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const files = e.dataTransfer.files;

        if (files) {
          onUpload(files);
        }
      }}
      onDragOver={e => {
        e.preventDefault();
      }}
      component="label"
      borderRadius={4}
      sx={{
        border: '1px dashed',
        borderColor: '#414141',
        background: `linear-gradient(${
          isLightBackground ? '#414141' : '#2B2B2B'
        } 0 0) padding-box, linear-gradient(to right, #18C7D7, #0E8EF3) border-box` /* Gradient background */,
        display: 'flex',
        width: '100%',
        height: height,
        borderRadius: 1,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: !disabled ? 'copy' : 'not-allowed',
        userSelect: 'none'
      }}
    >
      {title}
      <UploadArea disabled={disabled} multiple={multiple} onUpload={onUpload} />
    </Box>
  );
}
