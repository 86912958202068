import { Box, Button, Typography } from '@mui/material';
import paymentService from '../services/paymentService';

interface PlansBoxProps {
  title: string;
  subtitle: string;
  content: string;
  isCurrent: boolean;
  onCancel: () => void;
  onClick: () => void;
}

export default function PlansBox({ title, subtitle, content, isCurrent, onClick, onCancel }: PlansBoxProps) {
  const isMobile = window.innerWidth <= 600; //instead useTheme it's should be fixed later

  return (
    <Box
      sx={{
        border: '1px solid #494949',
        background: `linear-gradient(${
          isMobile ? 'black' : '#151515'
        } 0 0) padding-box, linear-gradient(to right, #18C7D7, #0E8EF3) border-box`,
        borderImage: isCurrent ? 'linear-gradient(#008EF3, #16E1F5)' : '#0000',
        display: 'flex',
        flexDirection: 'column',
        width: '256px',
        height: '307px',
        borderRadius: 1,
        padding: '12px 16px 16px 16px',
        position: 'relative',
        '@media (max-width: 600px)': {
          width: '353px'
        }
      }}
    >
      <Typography
        sx={{
          marginBottom: '6px',
          fontSize: '32px',
          fontWeight: 400,
          lineHeight: '40px'
        }}
      >
        {' '}
        {title}
      </Typography>
      <Typography
        sx={{
          //subtitle
          fontSize: '18px',
          fontWeight: 300,
          lineHeight: '18px',
          marginBottom: isCurrent ? '140px' : '128px'
        }}
      >
        {subtitle}
      </Typography>
      <Box component="ul">
        {content.split('- ').map(
          (item, index) =>
            item.trim() && (
              <Box
                component="li"
                key={index}
                sx={{
                  marginBottom: '5px',
                  '&:last-child': {
                    marginBottom: 0
                  },
                  listStyleType: 'none'
                }}
              >
                {
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '17px',
                      fontWeight: 400
                    }}
                  >
                    - {item.trim()}
                  </Typography>
                }
              </Box>
            )
        )}
        {!isCurrent && title !== 'Basic' && (
          <Button
            variant="contained"
            sx={{
              marginTop: '16px',
              padding: '5px 8px',
              fontSize: '12px',
              lineHeight: '18px',
              letterSpacing: '-0.4%',
              backgroundColor: '#008EF3',
              '&:hover': {
                backgroundColor: '#006DBA'
              }
            }}
            onClick={onClick}
          >
            Get Started
          </Button>
        )}
        {isCurrent && title !== 'Basic' && (
          <>
            <Typography
              sx={{
                position: 'absolute',
                bottom: '100px',
                backgroundImage: 'linear-gradient(to left, #008EF3 0%, #16E1F5 85%)',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: '18px',
                fontWeight: 300,
                lineHeight: '18px',
                whiteSpace: 'nowrap'
              }}
            >
              Your Current Plan
            </Typography>
            <Button
              variant="contained"
              sx={{
                marginTop: '4px',
                padding: '5px 8px',
                fontSize: '12px',
                lineHeight: '18px',
                letterSpacing: '-0.4%',
                background: 'unset',
                boxShadow: 'unset'
              }}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}
