import { Grid, Icon, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { DialogUploadSongArea } from '../../components/DialogUploadSongArea';

export type SongUploadAreaProps = {
  uploadSong: (file: File) => Promise<void>;
};

export function SongUploadArea({ uploadSong }: SongUploadAreaProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DialogUploadSongArea
      height="64px"
      multiple={true}
      title={
        <Grid container alignItems={'center'} justifyContent={'center'} columnGap={0.5}>
          <Grid item xs={'auto'}>
            <Typography textAlign="center" variant="body2">
              {isMobile ? 'Upload' : 'Drag and drop or upload'}
            </Typography>
          </Grid>
          <Grid item xs={'auto'}>
            <Icon>
              <img src={'/assets/songIconUploadPage.svg'} height={16} width={16} alt="song" />
            </Icon>
          </Grid>
          <Grid item xs={'auto'}>
            <Typography fontWeight={'bold'} variant={'body2'}>
              Song
            </Typography>
          </Grid>
          <Grid item xs={'auto'}>
            <Typography variant={'body2'}>here</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="center" variant="body2">
              (i.e. master, mix bus, instrumental, or rough mix, etc.)
            </Typography>
          </Grid>
        </Grid>
      }
      onUpload={files => uploadSong(files[0])}
    />
  );
}
