import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTokenStore } from '../store/token.store';

type AuthGuardProps = {
  children: ReactNode;
};

export function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const token = useTokenStore(state => state.token);
  const isTokenLoadingFinished = useTokenStore(state => state.isFinished);
  const setFinished = useTokenStore(state => state.setFinished);

  useEffect(() => {
    if (!location.pathname.includes('public')) {
      if (!isAuthenticated) {
        loginWithRedirect({
          authorizationParams: { redirect_uri: `${window.location.origin}/?url=${location.pathname}` }
        });
      }
    } else {
      setFinished(true);
    }
  }, [isAuthenticated, isLoading]);

  return isTokenLoadingFinished ? <>{children}</> : null;
}
