import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SharedFolderItem } from '../../../@types/sharing';
import FolderDropDownItem from './FolderDropDownItem';

type FolderDropDownProps = {
  folder: SharedFolderItem;
  handleAccordion: (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  activeAccordion: string | false;
  isPublic?: boolean;
  highlightedSongId: string;
  setHighlightedSongId: (songId: string) => void;
};

export default function SharedFolderDropDown({
  folder,
  handleAccordion,
  activeAccordion,
  isPublic = false,
  setHighlightedSongId,
  highlightedSongId
}: FolderDropDownProps) {
  const screenWidth = window.innerWidth;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const { songId } = useParams();

  return (
    <>
      {(isPublic && localStorage.getItem('isfs') === 'true') || !isPublic ? (
        <Accordion expanded={activeAccordion === folder.folderId} sx={{ boxShadow: 'none' }} onChange={handleAccordion(folder.folderId)}>
          <AccordionSummary
            onClick={e => {
              e.preventDefault();
              if (isPublic) {
                navigate(`public/${folder.folderId}/${folder.songs[0].id}`);
              }
            }}
            sx={{
              backgroundColor: '#151515',
              p: 0,
              '& .MuiAccordionSummary-content': { my: 0 },
              '& .MuiAccordionSummary-content.isExpanded': { my: 0 },
              '& .MuiAccordionSummary-content.Mui-expanded': { mt: '0 !improtant' }
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${folder.folderId}-content`}
            id={`${folder.folderId}-header`}
          >
            <Stack direction="row" spacing={2} alignItems="center" maxWidth={'100% !important'}>
              <Box
                width="48px"
                height="48px"
                position="relative"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background:
                    activeAccordion === folder.folderId
                      ? 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 225, 245, 0.85) 88.47%)'
                      : '#4B4B4B66',
                  borderRadius: 1
                }}
              >
                <FolderOutlinedIcon fontSize="medium" sx={{ position: 'absolute' }} />
              </Box>
              <Tooltip title={folder.folderName.length > 16 ? folder.folderName : ''}>
                <Typography
                  sx={{
                    '&.MuiTypography-root': {
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: isMobile ? '210px' : `${Math.round((screenWidth / 800) * 100)}px`
                    }
                  }}
                >
                  {folder.folderName}
                </Typography>
              </Tooltip>
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#151515' }}>
            <Grid container direction="column" spacing={1}>
              {folder.songs.map(song => (
                <Grid item key={song.id} maxWidth={'100% !important'}>
                  <FolderDropDownItem
                    song={song}
                    isPublic={isPublic}
                    setHighlightedSongId={setHighlightedSongId}
                    highlightedSongId={highlightedSongId}
                  />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ) : (
        <Grid container direction="column" spacing={1}>
          {folder.songs
            .filter(song => song.id === songId)
            .map(song => (
              <Grid item key={song.id} maxWidth={'100% !important'}>
                <FolderDropDownItem song={song} setHighlightedSongId={setHighlightedSongId} highlightedSongId={highlightedSongId} />
              </Grid>
            ))}
        </Grid>
      )}
    </>
  );
}
