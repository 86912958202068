import { Box, Button, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

export type MockedStemItemProps = {
  emptyStem?: boolean;
};

export function MockedStemItem({ emptyStem = false }: MockedStemItemProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <React.Fragment>
      <Stack
        sx={{
          ...(!emptyStem && { background: '#1F1F1F' }),
          maxHeight: isMobile ? '115px' : '60px',
          minHeight: isMobile ? '115px' : '60px'
        }}
        direction="row"
        py={1.5}
        pl={isMobile ? 1.25 : 3}
        pr={isMobile ? 1.25 : 2}
        justifyContent="space-between"
      >
        {!emptyStem && (
          <>
            {isMobile ? (
              <Stack direction="row" justifyContent={'space-between'}>
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    disableElevation={true}
                    disabled
                    sx={{
                      minWidth: 'unset',
                      width: '40px',
                      height: '40px',
                      mr: 1,
                      boxShadow: 'none',
                      '&.Mui-disabled': {
                        background: 'rgb(75, 75, 75, 0.5)'
                      }
                    }}
                  >
                    S
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    disableElevation={true}
                    disabled
                    sx={{
                      minWidth: 'unset',
                      width: '40px',
                      height: '40px',
                      boxShadow: 'none',
                      '&.Mui-disabled': {
                        background: 'rgb(75, 75, 75, 0.5)'
                      }
                    }}
                  >
                    M
                  </Button>
                </Box>
              </Stack>
            ) : (
              <Box>
                <Tooltip title="Solo">
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    disableElevation={true}
                    disabled
                    sx={{
                      mr: 1,
                      minWidth: 'unset',
                      width: '28px',
                      height: '28px',
                      boxShadow: 'none',
                      '&.Mui-disabled': {
                        background: 'rgb(75, 75, 75, 0.5)'
                      }
                    }}
                  >
                    S
                  </Button>
                </Tooltip>
                <Tooltip title="Mute">
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    disableElevation={true}
                    disabled
                    sx={{
                      minWidth: 'unset',
                      width: '28px',
                      height: '28px',
                      boxShadow: 'none',
                      '&.Mui-disabled': {
                        background: 'rgb(75, 75, 75, 0.5)'
                      }
                    }}
                  >
                    M
                  </Button>
                </Tooltip>
              </Box>
            )}
          </>
        )}
      </Stack>
      <Box height="1px" width="100%" sx={{ backgroundColor: '#494949' }} />
    </React.Fragment>
  );
}
