import { useAuth0 } from '@auth0/auth0-react';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import { Box, Button, Divider, Grid, Icon, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationType } from '../@types/notifications';
// @ts-ignore
import { ReactComponent as Wave } from '../assets/icons/wave.svg';
import { SongDownload } from '../sections/mainSongPage/SongDownload';
import { SongLinkCopy } from '../sections/mainSongPage/SongLinkCopy';
import { SongMetadata } from '../sections/mainSongPage/SongMetadata';
import { SongShowButton } from '../sections/mainSongPage/SongShowButton';
import notificationService from '../services/notificationService';
import songsService from '../services/songsService';
import { usePlaylistStore } from '../store/players.store';
import { useFoldersSelector } from '../store/selectors/folders.selector';
import { useSongSelector } from '../store/selectors/song.selector';
import { useUploadFilesSelector } from '../store/selectors/uploadFiles.selector';
import { formatDateMDY } from '../utils/date';
import { createFileList, getFile, isValidAudioFile, isZipFile, readDirectory } from '../utils/fileUtils';
import { generateId } from '../utils/generateId';
import { processUnZipFile } from '../utils/useUnzipWorker';
import { DialogUploadSongArea } from './DialogUploadSongArea';
import { MetadataSidebar } from './MetadataSidebar';
import { MockedStemItem } from './MockedStemItem';
import { SongSharingUploadPage } from './SongSharingUploadPage';
import { UploadArea } from './UploadArea';
import backgroundImage from '../assets/icons/TimeLine.png';
import { StorageStemItem } from './multitrack/StorageStemItem';

export function UploadPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(theme.breakpoints.down('md'));
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const [isSongShown, setIsSongShown] = useState(false);

  const { user } = useAuth0();
  let timer: string | number | NodeJS.Timeout | undefined;
  const { song, clearSong, currentVersion, createNewSong } = useSongSelector();
  const { setUploadData, createParentSong, lastQueue } = useUploadFilesSelector();

  const { defaultFolder } = useFoldersSelector();
  let playlist: any;
  const playlistState = usePlaylistStore(state => state.playListStates.find(playlistState => playlistState.versionId === currentVersion));
  if (playlistState) {
    playlist = playlistState.playlist;
  }

  const navigate = useNavigate();

  const [zoomLevel, setZoomLevel] = useState<number>(0);
  const [isDraggingStems, setIsDraggingStems] = useState(false);

  const multitrackContainerRef = useRef<HTMLDivElement | null>(null);

  const editDescriptionRef = useRef<null | HTMLInputElement>(null);
  const editSongNameRef = useRef<null | HTMLInputElement>(null);

  const [inputDescription, setInputDescription] = useState('');
  const [inputName, setInputName] = useState('');

  const [stems, setStems] = useState<any[]>(new Array(0));
  const [currentVideo, setCurrentVideo] = useState('');
  const [height, setHeight] = useState('0px');

  const getRandomVideo = () => {
    const videoSources = [
      '/assets/backgroundStemsVideo1.mp4',
      '/assets/backgroundStemsVideo2.mp4',
      '/assets/backgroundStemsVideo3.mp4',
      '/assets/backgroundStemsVideo4.mp4',
      '/assets/backgroundStemsVideo5.mp4',
      '/assets/backgroundStemsVideo6.mp4',
      '/assets/backgroundStemsVideo7.mp4',
      '/assets/backgroundStemsVideo8.mp4',
      '/assets/backgroundStemsVideo9.mp4',
      '/assets/backgroundStemsVideo10.mp4',
      '/assets/backgroundStemsVideo11.mp4',
      '/assets/backgroundStemsVideo12.mp4',
      '/assets/backgroundStemsVideo13.mp4',
      '/assets/backgroundStemsVideo14.mp4',
      '/assets/backgroundStemsVideo15.mp4',
      '/assets/backgroundStemsVideo16.mp4',
      '/assets/backgroundStemsVideo17.mp4',
      '/assets/backgroundStemsVideo19.mp4',
      '/assets/backgroundStemsVideo20.mp4'
    ];

    const randomIndex = Math.floor(Math.random() * videoSources.length);
    return videoSources[randomIndex];
  };

  useEffect(() => {
    setCurrentVideo(getRandomVideo());
  }, []);

  useEffect(() => {
    if (multitrackContainerRef.current?.scrollHeight) {
      let count = isMobile
        ? 0
        : Math.floor((multitrackContainerRef?.current?.scrollHeight! - 51 - 24 - (isMobile ? 115 : 60)) / (isMobile ? 115 : 60));

      setStems(new Array(count).fill('stem'));
    }
  }, [multitrackContainerRef]);

  useEffect(() => {
    const newHeight = `calc(100svh + 51px - 24px - ${multitrackContainerRef.current?.offsetTop ?? 0}px)`;
    setHeight(newHeight);
  }, [isSongShown]);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    clearSong();
  }, []);

  const handleZoomSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
    setZoomLevel(value as number);
  };

  const handleOnChangeOnEditDescription = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setInputDescription(input);
  };

  const handleKeyDownOnEditDescription = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      e.preventDefault();
      editDescriptionRef.current?.blur();
    }
  };

  const handleOnChangeName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setInputName(input);
  };

  const handleKeyDownOnSongRename = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      e.preventDefault();
      editSongNameRef.current?.blur();
    }
  };

  const triggerNavigating = async (song?: File, stems?: File[]) => {
    const parentSongId = generateId();
    const folderId = defaultFolder?.id || '';
    const enteredName = inputName.length ? inputName : undefined;

    const nameOfSong = enteredName ?? song?.name ?? 'DEMO_' + formatDateMDY(new Date());

    const parentSong = await createParentSong(folderId, parentSongId, nameOfSong);
    const firstVersion = await songsService.addSongVersion(parentSongId, nameOfSong, inputDescription ?? '');

    firstVersion.songName = nameOfSong;

    const stemsIds =
      stems?.map(
        _ =>
          'stem_' +
          generateId() +
          '_' +
          new Date()
            .toISOString()
            .replace(/[-T:.]/g, '_')
            .slice(0, -1)
      ) ?? [];

    createNewSong(
      song
        ? {
            id:
              'song_' +
              generateId() +
              '_' +
              new Date()
                .toISOString()
                .replace(/[-T:.]/g, '_')
                .slice(0, -1),
            mime: song.type,
            name: song.name,
            createdAt: new Date().toString(),
            updatedAt: new Date().toString(),
            uploadedBy: user?.name,
            size: song.size,
            order: 0,
            url: URL.createObjectURL(song)
          }
        : null,
      stems?.map((stem, i) => ({
        id: stemsIds[i],
        songParentId: parentSongId,
        mime: stem.type,
        name: stem.name,
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
        uploadedBy: user?.name,
        size: stem.size,
        order: i + 1,
        url: URL.createObjectURL(stem)
      })) ?? [],
      [firstVersion],
      firstVersion.id,
      parentSongId,
      inputDescription ?? '',
      new Date().toString(),
      new Date().toString(),
      user?.name,
      0
    );

    (song || stems) &&
      setUploadData(
        song ? { file: song, progress: 0, estimatedTime: 0 } : null,
        stems?.map((stem, i) => ({ file: stem, progress: 0, estimatedTime: 0, stemId: stemsIds[i], queueId: lastQueue + 1 })) ?? [],
        parentSongId,
        folderId,
        true
      );

    notificationService.createNotification(NotificationType.UploadSong, parentSongId, folderId);

    navigate(`/dashboard`);
    timer = setTimeout(() => navigate(`/dashboard/${folderId}/${parentSongId}`), 20);
  };

  const uploadSong = (file: File) => {
    triggerNavigating(file);
  };

  const uploadStems = async (files: FileList) => {
    const newStems: File[] = [];

    for (let i = 0; i < files.length; i++) {
      if (isValidAudioFile(files[i])) {
        newStems.push(files[i]);
      }
      if (isZipFile(files[i])) {
        const stems = await processUnZipFile(files[i]);
        newStems.push(...stems);
      }
    }

    triggerNavigating(undefined, newStems);
  };

  return (
    <Grid container height={1} direction={'row'} columnSpacing={2}>
      <Grid item xs={!isMobile && isSidebarOpened ? 8.8 : 12}>
        <Stack direction="column" height={1} sx={{ borderRadius: '8px' }}>
          {isMobile ? (
            <Stack
              direction="column"
              rowGap={1.5}
              pt={2}
              pb={3}
              sx={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                background: 'linear-gradient(180deg, rgba(0, 142, 243, 0.3) 0%, rgba(0, 142, 243, 0.54) 0.02%, rgba(18, 18, 18, 0) 110%)'
              }}
            >
              <Stack direction="row" justifyContent="flex-end" px={2}>
                <Stack direction="row" alignItems="flex-start" columnGap={'11px'}>
                  <Button
                    disabled={true}
                    size="small"
                    color="info"
                    variant="contained"
                    disableElevation={true}
                    sx={{
                      border: '1px solid #FFFFFF',
                      height: '35px',
                      width: '62px',
                      padding: '10px 12px',
                      fontSize: '14px',
                      minWidth: 'unset',
                      '&.Mui-disabled': {
                        backgroundColor: 'info.main',
                        color: '#FFFF',
                        opacity: '50%'
                      }
                    }}
                  >
                    Share
                  </Button>
                  <SongLinkCopy disabled={true} />
                  <SongDownload disabled={true} />
                </Stack>
              </Stack>
              <Divider />
              <Stack direction="column" rowGap={1} px={2}>
                <TextField
                  variant="standard"
                  value={inputName}
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '26px', sm: '32px' },
                    lineHeight: { xs: '34px', sm: '40px' },
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    '& .MuiInputBase-input': {
                      fontSize: { xs: '26px', sm: '32px' },
                      lineHeight: { xs: '34px', sm: '40px' },
                      padding: '0',
                      border: 'none',
                      outline: 'none',
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                      color: '#FFFF',
                      transition: 'none',
                      caretColor: '#FFFF'
                    },
                    '.MuiInput-underline:before, .MuiInput-underline:after': {
                      borderBottom: 'none !important'
                    },
                    '.MuiInput-underline:hover:not(.Mui-disabled):before': {
                      borderBottom: 'none !important'
                    },
                    '.MuiInput-underline:focus:before': {
                      borderBottom: 'none !important'
                    }
                  }}
                  fullWidth
                  inputRef={editSongNameRef}
                  onKeyDown={handleKeyDownOnSongRename}
                  onChange={handleOnChangeName}
                  placeholder="Song title"
                />
                <TextField
                  id="outlined-textarea"
                  variant="standard"
                  multiline
                  maxRows={4}
                  value={inputDescription}
                  fullWidth
                  inputRef={editDescriptionRef}
                  onKeyDown={handleKeyDownOnEditDescription}
                  onChange={handleOnChangeOnEditDescription}
                  placeholder="Description"
                  sx={{
                    '& .MuiInputBase-input': {
                      maxWidth: '450px',
                      fontSize: '16px',
                      padding: '0',
                      border: 'none',
                      outline: 'none',
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                      color: '#FFFF',
                      lineHeight: '1.5',
                      transition: 'none',
                      caretColor: '#FFFF'
                    },
                    '.MuiInput-underline:before, .MuiInput-underline:after': {
                      borderBottom: 'none !important'
                    },
                    '.MuiInput-underline:hover:not(.Mui-disabled):before': {
                      borderBottom: 'none !important'
                    },
                    '.MuiInput-underline:focus:before': {
                      borderBottom: 'none !important'
                    }
                  }}
                />
              </Stack>

              <Stack direction="row" px={2} gap={1}>
                <SongMetadata isSidebarOpened={isSidebarOpened} toggleSidebar={() => setIsSidebarOpened(prev => !prev)} />
                <SongShowButton isSongShown={isSongShown} toggleSongShown={() => setIsSongShown(prev => !prev)} song={song} />
              </Stack>
              {isSongShown && (
                <Box px={2} mt={1.5}>
                  <DialogUploadSongArea
                    height="95px"
                    multiple={true}
                    title={
                      <Grid container alignItems={'center'} justifyContent={'center'} columnGap={0.5}>
                        <Grid item xs={'auto'}>
                          <Typography textAlign="center" variant="body2">
                            Upload
                          </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                          <Icon>
                            <img src={'/assets/songIconUploadPage.svg'} height={16} width={16} alt="song" />
                          </Icon>
                        </Grid>
                        <Grid item xs={'auto'}>
                          <Typography fontWeight={'bold'} variant={'body2'}>
                            Song
                          </Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                          <Typography variant={'body2'}>here</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography textAlign="center" variant="body2">
                            (i.e. master, mix bus, instrumental, or rough mix, etc.)
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                    onUpload={files => uploadSong(files[0])}
                  />
                </Box>
              )}
            </Stack>
          ) : (
            <Stack
              direction="column"
              rowGap={1.5}
              p={3}
              pb={1}
              sx={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                background: 'linear-gradient(180deg, rgba(0, 142, 243, 0.3) 0%, rgba(0, 142, 243, 0.54) 0.02%, rgba(18, 18, 18, 0) 110%)'
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <TextField
                  variant="standard"
                  value={inputName}
                  sx={{
                    fontWeight: 400,
                    fontSize: { xs: '26px', sm: '32px' },
                    lineHeight: { xs: '34px', sm: '40px' },
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    '& .MuiInputBase-input': {
                      fontSize: { xs: '26px', sm: '32px' },
                      lineHeight: { xs: '34px', sm: '40px' },
                      padding: '0',
                      border: 'none',
                      outline: 'none',
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                      color: '#FFFF',
                      transition: 'none',
                      caretColor: '#FFFF'
                    },
                    '.MuiInput-underline:before, .MuiInput-underline:after': {
                      borderBottom: 'none !important'
                    },
                    '.MuiInput-underline:hover:not(.Mui-disabled):before': {
                      borderBottom: 'none !important'
                    },
                    '.MuiInput-underline:focus:before': {
                      borderBottom: 'none !important'
                    }
                  }}
                  fullWidth
                  inputRef={editSongNameRef}
                  onKeyDown={handleKeyDownOnSongRename}
                  onChange={handleOnChangeName}
                  placeholder="Song title"
                />
                <Stack direction="row" alignItems="flex-start" sx={{ gap: '11px' }}>
                  <SongSharingUploadPage />
                  <SongLinkCopy disabled={true} />
                  <SongDownload disabled={true} />
                </Stack>
              </Stack>
              <Stack direction="row" gap={'8px'}>
                <SongMetadata isSidebarOpened={isSidebarOpened} toggleSidebar={() => setIsSidebarOpened(prev => !prev)} />
                <SongShowButton isSongShown={isSongShown} toggleSongShown={() => setIsSongShown(prev => !prev)} song={song} />
              </Stack>
              <Grid container>
                <Grid item xs={5}>
                  <Stack direction="column">
                    <TextField
                      id="outlined-textarea"
                      variant="standard"
                      multiline
                      maxRows={4}
                      value={inputDescription}
                      fullWidth
                      inputRef={editDescriptionRef}
                      onKeyDown={handleKeyDownOnEditDescription}
                      onChange={handleOnChangeOnEditDescription}
                      placeholder="Description"
                      sx={{
                        '& .MuiInputBase-input': {
                          maxWidth: '450px',
                          fontSize: '16px',
                          padding: '0',
                          border: 'none',
                          outline: 'none',
                          boxShadow: 'none',
                          backgroundColor: 'transparent',
                          color: '#FFFF',
                          lineHeight: '1.5',
                          transition: 'none',
                          caretColor: '#FFFF'
                        },
                        '.MuiInput-underline:before, .MuiInput-underline:after': {
                          borderBottom: 'none !important'
                        },
                        '.MuiInput-underline:hover:not(.Mui-disabled):before': {
                          borderBottom: 'none !important'
                        },
                        '.MuiInput-underline:focus:before': {
                          borderBottom: 'none !important'
                        }
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={7} pl={1}>
                  {isSongShown && (
                    <DialogUploadSongArea
                      height="95px"
                      multiple={true}
                      title={
                        <Grid container alignItems={'center'} justifyContent={'center'} columnGap={0.5}>
                          <Grid item xs={'auto'}>
                            <Typography textAlign="center" variant="body2">
                              Drag and drop or upload
                            </Typography>
                          </Grid>
                          <Grid item xs={'auto'}>
                            <Icon>
                              <img src={'/assets/songIconUploadPage.svg'} height={16} width={16} alt="song" />
                            </Icon>
                          </Grid>
                          <Grid item xs={'auto'}>
                            <Typography fontWeight={'bold'} variant={'body2'}>
                              Song
                            </Typography>
                          </Grid>
                          <Grid item xs={'auto'}>
                            <Typography variant={'body2'}>here</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography textAlign="center" variant="body2">
                              (i.e. master, mix bus, instrumental, or rough mix, etc.)
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                      onUpload={files => uploadSong(files[0])}
                    />
                  )}
                </Grid>
              </Grid>
            </Stack>
          )}
          {isMobile && (
            <Box display={'flex'} position={'relative'} flexGrow={1} height={1} ref={multitrackContainerRef}>
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  zIndex: 10
                }}
              >
                <Box
                  sx={{
                    fontFamily: 'inter',
                    display: 'flex',
                    cursor: 'copy',
                    background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.85) 88.47%)',
                    width: '100%',
                    height: '100%',
                    userSelect: 'none',
                    color: 'white',
                    fontSize: '28px',
                    borderRadius: '0px 0px 8px 8px'
                  }}
                >
                  <Box
                    component="label"
                    onDrop={(e: React.DragEvent<HTMLDivElement>) => {
                      if (!isDraggingStems) return;
                      e.preventDefault();
                      const files = e.dataTransfer.files;
                      setIsDraggingStems(false);
                      uploadStems(files);
                    }}
                    onDragEnter={() => {
                      setIsDraggingStems(true);
                    }}
                    onDragLeave={() => {
                      setIsDraggingStems(false);
                    }}
                    onDragEnd={() => {
                      setIsDraggingStems(false);
                    }}
                    onDragOver={e => {
                      e.preventDefault();
                    }}
                    sx={{ position: 'absolute', width: '100%', height: '100%', cursor: 'copy', zIndex: 20 }}
                  >
                    <UploadArea onUpload={uploadStems} multiple={true} />
                  </Box>
                  <Stack alignItems="center" direction="column">
                    <img style={{ marginTop: 24, marginBottom: 24 }} src="/assets/uploadIconForMobile.svg" alt="Upload icon for mobile" />
                    <Typography textAlign="center" fontSize={32} fontWeight={400}>
                      Upload Stems
                    </Typography>
                    <Typography textAlign="center" fontSize={24} mt={3} fontWeight={400}>
                      (i.e. individual tracks, buses, groups, etc.)
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Box>
          )}
          {!isMobile && (
            <Grid
              container
              flexGrow={1}
              height={`calc(100svh + 51px - 24px - ${multitrackContainerRef.current?.offsetTop ?? 0}px)`}
              maxHeight={height}
              overflow={'hidden'}
              direction="row"
              sx={{ backgroundColor: '#151515', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
            >
              <Grid item xs={2.4}>
                <Stack
                  direction="row"
                  pl={3}
                  pr={2}
                  py={'11px'}
                  justifyContent="space-between"
                  sx={{
                    background: '#151515',
                    borderBottom: '1px solid #494949',
                    borderTop: '1px solid #494949'
                  }}
                >
                  <Button
                    size="small"
                    sx={{
                      minWidth: 'unset',
                      width: '28px',
                      height: '28px',
                      cursor: 'unset',
                      opacity: '0.5',
                      '&.Mui-disabled': {
                        background: 'rgb(25, 118, 210)'
                      }
                    }}
                    variant="contained"
                    color="info"
                    disabled
                  >
                    <PlayArrowIcon fontSize="small" sx={{ margin: 0 }} />{' '}
                  </Button>
                </Stack>
                {stems.map((s, i) => (
                  <MockedStemItem key={s} emptyStem={isMobile ? i > 2 : i > 5} />
                ))}
              </Grid>
              <Grid item xs={9.6}>
                <Box
                  sx={{
                    height: '51px',
                    objectFit: 'cover',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                />
                {/* Design of upload stem area with video */}
                <Box display={'flex'} position={'relative'} flexGrow={1} height={1} maxWidth={'100%'} ref={multitrackContainerRef}>
                  <Box
                    component="label"
                    onDrop={async (e: React.DragEvent<HTMLDivElement>) => {
                      if (!isDraggingStems) return;
                      e.preventDefault();

                      const items = e.dataTransfer.items;
                      let files: File[] = [];

                      const filePromises = Array.from(items).map(async item => {
                        const entry = item.webkitGetAsEntry();

                        if (entry) {
                          if (entry.isDirectory) {
                            await readDirectory(entry as FileSystemDirectoryEntry, files);
                          } else if (entry.isFile) {
                            const file = await getFile(entry as FileSystemFileEntry);
                            files.push(file);
                          }
                        }
                      });

                      await Promise.all(filePromises);

                      if (files.length > 0) {
                        await uploadStems(createFileList(files));
                      }
                    }}
                    onDragEnter={() => {
                      setIsDraggingStems(true);
                    }}
                    onDragLeave={() => {
                      setIsDraggingStems(false);
                    }}
                    onDragEnd={() => {
                      setIsDraggingStems(false);
                    }}
                    onDragOver={e => {
                      e.preventDefault();
                    }}
                    sx={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      cursor: 'copy',
                      zIndex: 20
                    }}
                  >
                    <UploadArea onUpload={uploadStems} multiple={true} />
                  </Box>

                  <video
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      overflow: 'hidden'
                    }}
                    loop
                    autoPlay
                    preload="metadata"
                    muted
                  >
                    {currentVideo && <source src={currentVideo} type="video/mp4" />}
                  </video>

                  <Box
                    sx={{
                      fontFamily: 'DM Sans, sans-serif',
                      display: 'flex',
                      cursor: 'copy',
                      border: '1px solid rgba(255, 255, 255, 0.2);',
                      justifyContent: 'center',
                      alignItems: 'center',
                      userSelect: 'none',
                      color: 'white',
                      fontSize: '28px',
                      borderRadius: 1,
                      position: 'absolute',
                      top: '33%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '30%',
                      backgroundColor: 'rgba(19, 20, 20, 0.5)',
                      backdropFilter: 'blur(4px)'
                    }}
                  >
                    <Stack alignItems="center" direction="column">
                      <FileUploadOutlinedIcon sx={{ paddingTop: '9px', fontSize: '44px' }} />

                      <Typography paddingTop={'14px'} textAlign="center" fontSize={28} lineHeight={'40px'} color="#FFFFF">
                        Drop stems here
                      </Typography>
                      <Typography paddingBlock={'10px'} textAlign="center" fontSize={18} lineHeight={'40px'} color="#ABABAB">
                        (i.e. individual tracks, buses, groups, etc.)
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Stack>
      </Grid>
      {isSidebarOpened && (
        <Grid item xs={!isMobile && isSidebarOpened ? 3.2 : 0} height={1}>
          <MetadataSidebar playlist={playlist} isSidebarOpened={isSidebarOpened} closeSidebar={() => setIsSidebarOpened(false)} />
        </Grid>
      )}
    </Grid>
  );
}
