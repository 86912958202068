import Grid from '@mui/material/Grid';
import { Folder } from '../../../@types/folders';
import FolderDropDownItem from './FolderDropDownItem';

export default function FolderDropDownItemsList({
  folder,
  highlightedSongId,
  setHighlightedSongId
}: {
  folder: Folder;
  highlightedSongId: string;
  setHighlightedSongId: (songId: string) => void;
}) {
  return (
    <Grid container direction="column" spacing={1}>
      {folder.songs.map(song => (
        <Grid item key={song.id} maxWidth={'100% !important'}>
          <FolderDropDownItem highlightedSongId={highlightedSongId} setHighlightedSongId={setHighlightedSongId} song={song} />
        </Grid>
      ))}
    </Grid>
  );
}
