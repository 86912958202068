import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SubNavbar from '../components/SubNavbar';
import PlansBox from '../components/PlansBox';

import { useEffect, useState } from 'react';
import { PlansButton } from '../components/PlansButton';
import CancelledSubscriptionInfo from '../sections/plans/CancelledSubscriptionInfo';
import paymentService from '../services/paymentService';
import CancelSubscription from '../sections/plans/CancelSubscription';

const OvalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '4px',
  width: 'fit-content',
  height: 'fit-content',
  gap: '4px',
  borderRadius: 38,
  backgroundColor: 'rgba(139, 139, 139, 0.3)'
}));

export default function Plans() {
  const [billing, setBilling] = useState<'Yearly' | 'Monthly'>('Yearly');
  const [isCancelling, setIsCancelling] = useState(false);
  const [userPlan, setUserPlan] = useState<
    { plan: string; period: 'Yearly' | 'Monthly'; expiresAt: string; isCancelled: boolean } | undefined
  >(undefined);

  const [isCancelled, setIsCancelled] = useState(false);

  const handleBillingChange = (newbilling: 'Yearly' | 'Monthly') => {
    setBilling(newbilling);
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  const fetchPlan = async () => {
    paymentService.getUserPlan().then(plan => {
      setBilling(plan?.period ?? 'Yearly');
      setUserPlan(plan);
      setIsCancelled(!!plan?.isCancelled);
    });
  };

  return (
    <Box
      height={1}
      sx={{
        backgroundColor: {
          xs: 'transparent',
          sm: '#151515'
        },
        borderRadius: '8px'
      }}
    >
      <SubNavbar />
      <Box sx={{ paddingTop: '40px', display: 'flex', justifyContent: 'center' }}>
        {!!userPlan && isCancelled && <CancelledSubscriptionInfo expiresAt={userPlan.expiresAt} onClose={() => setIsCancelled(false)} />}
        {!!userPlan && !isCancelled && isCancelling && (
          <CancelSubscription refetchPlan={fetchPlan} onClose={() => setIsCancelling(false)} />
        )}{' '}
        {!isCancelled && !isCancelling && (
          <Stack direction="column" justifyContent={'center'} gap="21px" sx={{ paddingInline: '24px' }}>
            <Stack direction="row" justifyContent={'space-between'} alignItems="center">
              <Typography fontSize={28} sx={{ lineHeight: '35px' }}>
                Plans
              </Typography>
              <OvalContainer>
                <PlansButton billing={billing} onClick={() => handleBillingChange('Yearly')} children={'Yearly'} />

                <PlansButton billing={billing} onClick={() => handleBillingChange('Monthly')} children={'Monthly'} />
              </OvalContainer>
            </Stack>
            <Grid container direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'center'}>
              <Grid item>
                <PlansBox
                  isCurrent={!userPlan}
                  title={'Basic'}
                  subtitle={'$0 / Month'}
                  content={'- 2GB of storage - DEMO Plug-in'}
                  onClick={() => {}}
                  onCancel={() => {}}
                />
              </Grid>
              <Grid item>
                <PlansBox
                  isCurrent={userPlan?.plan === 'Premium' && userPlan?.period === billing}
                  title={'Premium'}
                  subtitle={billing === 'Yearly' ? '$9.99 / Month' : '$11.99 / Month'}
                  content={'- 2,000GB of storage (2TB) - DEMO Plug-in'}
                  onCancel={() => setIsCancelling(true)}
                  onClick={async () => {
                    userPlan
                      ? await paymentService
                          .changePlan(billing === 'Yearly' ? 'price_1PlB2YKlcaicp0J6TzMjE5kX' : 'price_1PlB2YKlcaicp0J6ZOHKPZp1')
                          .then(() => fetchPlan())
                      : await paymentService
                          .createCheckoutSession(billing === 'Yearly' ? 'price_1PlB2YKlcaicp0J6TzMjE5kX' : 'price_1PlB2YKlcaicp0J6ZOHKPZp1')
                          .then(url => {
                            window.location.href = url;
                          });
                  }}
                />
              </Grid>
              <Grid item>
                <PlansBox
                  isCurrent={userPlan?.plan === 'Creator' && userPlan?.period === billing}
                  title={'Creator'}
                  subtitle={billing === 'Yearly' ? '$19.99 / Month' : '$22.99 / Month'}
                  content={'- 10,000GB of storage (10TB) - DEMO Plug-in'}
                  onCancel={() => setIsCancelling(true)}
                  onClick={async () => {
                    userPlan
                      ? await paymentService
                          .changePlan(billing === 'Yearly' ? 'price_1PlB2VKlcaicp0J6J4tGJF76' : 'price_1PlB2VKlcaicp0J6GuTDNQUz')
                          .then(() => fetchPlan())
                      : await paymentService
                          .createCheckoutSession(billing === 'Yearly' ? 'price_1PlB2VKlcaicp0J6J4tGJF76' : 'price_1PlB2VKlcaicp0J6GuTDNQUz')
                          .then(url => {
                            window.location.href = url;
                          });
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        )}
      </Box>
    </Box>
  );
}
