import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SharedFolderItem } from '../../../@types/sharing';
import { Song } from '../../../@types/songs';
import FolderDropDownItem from './FolderDropDownItem';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import SharedFolderDropDown from './SharedFolderDropDown';

type SharedDropDownProps = {
  handleAccordion: (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  activeAccordion: string | false;
  files: Song[];
  folders: SharedFolderItem[];
  highlightedSongId: string;
  setHighlightedSongId: (songId: string) => void;
};

export function SharedDropDown({
  activeAccordion,
  handleAccordion,
  files,
  folders,
  setHighlightedSongId,
  highlightedSongId
}: SharedDropDownProps) {
  const [activeFolder, setActiveFolder] = useState<string | false>(false);

  const handleChange = (folderId: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setActiveFolder(newExpanded ? folderId : false);
  };

  useEffect(() => {
    if (activeAccordion !== 'sharing') {
      setActiveFolder(false);
    }
  }, [activeAccordion]);

  return (
    <>
      <Accordion expanded={activeAccordion === 'sharing'} sx={{ boxShadow: 'none' }} onChange={handleAccordion('sharing')}>
        <AccordionSummary
          sx={{
            backgroundColor: '#151515',
            p: 0,
            '& .MuiAccordionSummary-content': { my: 0 },
            '& .MuiAccordionSummary-content.isExpanded': { my: 0 },
            margin: 0
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`sharing-content`}
          id={`sharing-header`}
        >
          <Stack direction="row" spacing={2} alignItems="center" maxWidth={'100% !important'}>
            <Box
              width="48px"
              height="48px"
              position="relative"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background:
                  activeAccordion === 'sharing' ? 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 225, 245, 0.85) 88.47%)' : '#4B4B4B66',
                borderRadius: 1
              }}
            >
              <PersonAddOutlinedIcon fontSize="medium" sx={{ position: 'absolute' }} />
            </Box>
            <Typography>Shared Files</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: '#151515' }}>
          <Grid container direction="column" spacing={1}>
            {folders?.map(folder => (
              <Grid item key={folder.folderId} maxWidth={'100% !important'}>
                <SharedFolderDropDown
                  folder={folder}
                  handleAccordion={handleChange}
                  activeAccordion={activeFolder}
                  highlightedSongId={highlightedSongId}
                  setHighlightedSongId={setHighlightedSongId}
                />
              </Grid>
            ))}
            {files?.map(file => (
              <Grid item key={file.id} maxWidth={'100% !important'}>
                <FolderDropDownItem song={file} highlightedSongId={highlightedSongId} setHighlightedSongId={setHighlightedSongId} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
