import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, Stack, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { NotificationType } from '../@types/notifications';
import { AudioFile } from '../@types/songs';
import notificationService from '../services/notificationService';
import songMetadataService from '../services/songMetadataService';
import trackEvent from '../services/trackService';
import { usePlaylistStore } from '../store/players.store';
import { useFoldersSelector } from '../store/selectors/folders.selector';
import { useSongSelector } from '../store/selectors/song.selector';
import { useUploadFilesSelector } from '../store/selectors/uploadFiles.selector';
import { formatDateMDY } from '../utils/date';
import { generateId } from '../utils/generateId';
import { useBeforeUnload } from '../utils/useBeforeUnload';
import { DialogCloseButton } from './DialogCloseButton';
import { DialogUploadSongArea } from './DialogUploadSongArea';
import { processUnZipFile } from '../utils/useUnzipWorker';

type UploadNewSongVersionDialogProps = {
  open: boolean;
  onClose: () => void;
  folderId: string;
  songId: string;
  rerender: (stems: AudioFile[]) => void;
};

export default function UploadNewSongVersionDialog({ open, onClose, folderId, songId, rerender }: UploadNewSongVersionDialogProps) {
  useBeforeUnload();
  const [song, setSong] = useState<File | null>(null);
  const [stems, setStems] = useState<File[]>([]);
  const { addVersion, changeVersion, isUploading, setCachedFiles, renameSong } = useSongSelector();
  const { setUploadData, lastQueue } = useUploadFilesSelector();

  const init = usePlaylistStore(state => state.init);
  const handleIsStemsRendered = usePlaylistStore(state => state.handleIsStemsRendered);
  const setStemsCountForLoading = usePlaylistStore(state => state.setStemsCountForLoading);
  const [description, setDescription] = useState<string>('');
  const { renameSongInSidebar } = useFoldersSelector();
  const { enqueueSnackbar } = useSnackbar();
  

  const handleCreateNewVersion = async () => {
    const songName = song?.name ?? 'DEMO_' + formatDateMDY(new Date());

    const newVersion = await addVersion(songId, songName, description);

    if (newVersion?.songName) {
      newVersion.songName = songName;
    }

    init(newVersion?.id!);
    enqueueSnackbar('New version created successfully', { variant: 'success' });

    const metadata = await songMetadataService.getFullMetadata(newVersion?.id!);

    await changeVersion(newVersion?.id!);

    const stemsIds = stems.map(
      _ =>
        'stem_' +
        generateId() +
        '_' +
        new Date()
          .toISOString()
          .replace(/[-T:.]/g, '_')
          .slice(0, -1)
    );

    const storeStems: AudioFile[] = stems.map((stem, i) => ({
      id: stemsIds[i],
      mime: stem.type,
      name: stem.name,
      size: stem.size,
      order: i + 1,
      url: URL.createObjectURL(stem)
    }));

    renameSongInSidebar(folderId!, songId!, songName);
    setStemsCountForLoading(storeStems.length, newVersion?.id!);
    setCachedFiles(
      song
        ? {
            id:
              'song_' +
              generateId() +
              '_' +
              new Date()
                .toISOString()
                .replace(/[-T:.]/g, '_')
                .slice(0, -1),
            mime: song.type,
            name: song.name,
            size: song.size,
            order: 0,
            url: URL.createObjectURL(song)
          }
        : null,
      storeStems,
      metadata,
      songId,
      description ?? '',
      0
    );

    rerender(storeStems);

    setUploadData(
      song ? { file: song, progress: 0, estimatedTime: 0 } : null,
      stems.map((stem, i) => ({ file: stem, progress: 0, estimatedTime: 0, stemId: stemsIds[i], queueId: lastQueue + 1 })),
      songId,
      folderId
    );

    notificationService.createNotification(NotificationType.UploadNewVersion, songId);
    trackEvent('upload_new_version', { songId });
    onClose();
  };

  const isZipFile = (file: File): boolean => {
    return file.type === 'application/zip' || file.name.endsWith('.zip');
  };

  const handleUploadSong = (files: FileList) => {
    setSong(files[0] ?? null);
  };

  const handleUploadStems = async (files: FileList) => {
    const newStems: File[] = [];

    if (files.length === 1 && isZipFile(files[0])) {
      const stems = await processUnZipFile(files[0]);
      newStems.push(...stems);
    } else {
      for (let i = 0; i < files.length; i++) {
        newStems.push(files[i]);
      }
    }

    setStems(prevStems => [...prevStems, ...newStems]);
  };

  const handleDeleteSong = () => {
    setSong(null);
  };

  const handleDeleteStem = (stemIndex: number) => {
    setStems(prevStems => prevStems.filter((stem, i) => stemIndex !== i));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <DialogCloseButton onClose={onClose} />
      <DialogTitle>Upload new version</DialogTitle>
      <DialogContent dividers sx={{ position: 'relative' }}>
        <Stack spacing={1} height={1}>
          <TextField
            label="Song version description here (optional)"
            multiline
            rows={5}
            fullWidth
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          {song ? (
            <Stack flexDirection="column" spacing={1.25}>
              <Typography>Song</Typography>
              <Stack
                sx={{ background: 'rgba(44, 44, 44, 0.40)' }}
                p={1.25}
                borderRadius={0.5}
                flexDirection="row"
                justifyContent="space-between"
              >
                <Stack flexDirection="row" alignItems="center" columnGap={1}>
                  <Icon>
                    <img src={'/assets/song.svg'} height={24} width={24} alt="song" />
                  </Icon>
                  <Typography fontWeight={300} fontSize={14}>
                    {song.name}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" alignItems="center" columnGap={1}>
                  <Button
                    disabled={isUploading}
                    onClick={handleDeleteSong}
                    variant="contained"
                    size="small"
                    color="secondary"
                    sx={{ height: '28px', width: '28px', minWidth: 'unset' }}
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <DialogUploadSongArea
              disabled={isUploading}
              height="140px"
              title={
                <Typography textAlign="center" variant="body2">
                  Drag and drop or upload the <br /> associated Song here
                </Typography>
              }
              onUpload={handleUploadSong}
            />
          )}
          {!!stems.length ? (
            <Stack flexDirection="column" spacing={1.25}>
              <Typography>Stems</Typography>
              <SimpleBar style={{ maxHeight: '200px' }}>
                <Grid container flexDirection="column" rowGap={0.5}>
                  {stems.map((stem, i) => (
                    <Grid item key={stem.name}>
                      <Stack
                        sx={{ background: 'rgba(44, 44, 44, 0.40)' }}
                        p={1.25}
                        borderRadius={0.5}
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Stack flexDirection="row" alignItems="center" columnGap={1}>
                          <Icon>
                            <img src={'/assets/wave.svg'} height={24} width={24} alt="song" />
                          </Icon>
                          <Typography fontWeight={300} fontSize={14}>
                            {stem.name}
                          </Typography>
                        </Stack>
                        <Stack flexDirection="row" alignItems="center" columnGap={1}>
                          <Button
                            disabled={isUploading}
                            onClick={() => handleDeleteStem(i)}
                            variant="contained"
                            size="small"
                            color="secondary"
                            sx={{ height: '28px', width: '28px', minWidth: 'unset' }}
                          >
                            <DeleteIcon fontSize="small" />
                          </Button>
                        </Stack>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </SimpleBar>
            </Stack>
          ) : (
            <DialogUploadSongArea
              disabled={isUploading}
              height="140px"
              multiple={true}
              title={
                <Typography textAlign="center" variant="body2">
                  Drag and drop or upload the <br /> associated Stems here
                </Typography>
              }
              onUpload={handleUploadStems}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isUploading} onClick={() => handleCreateNewVersion()} size="small" variant="contained" color="info">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
