import {useNavigate, useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {apiUrl} from '../../../configs';
import axios from '../../../utils/axios';

const ShareRedirect = () => {
  const { folderId, songId, type} = useParams();
  const navigate = useNavigate();
  const url = `${apiUrl}/share/song-share/${songId}/${type}`;

  useEffect(() => {
    const getData = async () => {
      return await axios.get(url);
    };
    (async () => {
      try {
        const res = await getData();
        res.status === 204 && navigate(`/dashboard/${folderId}/${songId}`);
      } catch (error) {
        navigate(`/dashboard`);
      }
    })();
  }, []);

  return <></>;
};

export default ShareRedirect;
