import { Box, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useEffect, useState } from 'react';
import TimeScale from '../../components/multitrack/Timescale';
import { useSongSelector } from '../../store/selectors/song.selector';

export type SongBPMProps = {
  canEdit: boolean;
  playlist: any;
};

export function SongBPM({ canEdit, playlist }: SongBPMProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { editBPM, bpm } = useSongSelector();
  const [inputBpm, setInputBpm] = useState(0);

  useEffect(() => {
    setInputBpm(Number(bpm));
  }, [bpm]);

  const handleBpmChange = async (event: ChangeEvent<{ value: string }>) => {
    const bpmNum = Number(event.target.value);

    if (!event.target.value.match(/[^0-9]/)) {
      setInputBpm(bpmNum);

      if (bpmNum >= 0) {
        if (playlist) {
          playlist.renderTimeScale = function () {
            const controlWidth = this.controls.show ? this.controls.width : 0;
            const timeScale = new TimeScale(
              this.duration,
              this.scrollLeft,
              this.samplesPerPixel,
              this.sampleRate,
              this.colors,
              controlWidth,
              bpmNum
            );
            return timeScale.render();
          };
          playlist.drawRequest();
        }
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '12px'
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          lineHeight: '17px',
          color: '#ffff'
        }}
      >
        BPM
      </Typography>
      {canEdit ? <TextField
        onBlur={e => {
          editBPM(Number(e.target.value));
          enqueueSnackbar('BPM changed successfully', { variant: 'success' });
        }}
        onChange={handleBpmChange}
        disabled={!canEdit}
        size="small"
        value={inputBpm}
        sx={{
          width: '45px',
          maxHeight: '27px',
          fontSize: '14px',
           '& .MuiOutlinedInput-root': {
             borderRadius: '8px',
        },
          '& fieldset': { border: 'none', borderRadius: '4px' },
          '& .MuiInputLabel-root': { fontSize: '14px' },
          '& .MuiInputBase-input': { fontSize: '14px', padding: '4px 10px', fontWeight: '400', lineHeight: '17px' },
          '& .MuiInputBase-root': { backgroundColor: '#2B2B2B', maxHeight: '27px' }
        }}
        inputProps={{
          min: '0',
          inputMode: 'numeric'
        }}
      /> : null }
      
    </Box>
  );
}
