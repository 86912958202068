import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { apiUrl } from '../../../configs';
import axios from '../../../utils/axios';

const ShareFolderRedirect = () => {
  const { folderId, type } = useParams();
  const navigate = useNavigate();
  const url = `${apiUrl}/share/folder-share/${folderId}/${type}`;

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get(url);
      return res;
    };

    (async () => {
      try {
        const res = await getData();
        res.status === 204 && navigate(`/dashboard`);
      } catch (error) {
        navigate(`/dashboard`);
      }
    })();
  }, []);

  return <></>;
};

export default ShareFolderRedirect;
