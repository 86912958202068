import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { UploadingProgressBar } from '../../components/UploadingProgressBar';
import { useUserStore } from '../../store/user.store';
import { NavBar } from './NavBar';

export default function MainLayout() {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useUserStore(state => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  const setupHotjar = () => {
    hotjar.initialize({
      id: 5102513,
      sv: 6
    });

    if (user) {
      hotjar.identify(user.id, { email: user.email });
    } else {
      hotjar.identify('incognito', { role: 'guest' });
    }
  };

  useEffect(() => {
    setupHotjar();
  }, [user]);

  useEffect(() => {
    if (hotjar.initialized()) {
      hotjar.stateChange(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (searchParams.has('url')) {
      navigate(searchParams.get('url') === '/' ? '/dashboard' : searchParams.get('url')!);
    }
  }, [searchParams]);

  return (
    <Stack height="100vh" overflow={'hidden'}>
      <UploadingProgressBar></UploadingProgressBar>
      <NavBar />
      <Outlet />
      <></>
    </Stack>
  );
}
