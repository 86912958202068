import { Autocomplete, Button, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Form, useLocation } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';

import { ReactComponent as ArrowDown } from '../assets/icons/arrow_down.svg';
import { ReactComponent as Expand } from '../assets/icons/expandButton.svg';
import { ReactComponent as Choice } from '../assets/icons/choice.svg';
import styled from '@emotion/styled';
import { AccessType, SharedUserRoles } from '../@types/sharing';
import sharingService from '../services/sharingService';
import { useSongSelector } from '../store/selectors/song.selector';
import { useUploadFilesSelector } from '../store/selectors/uploadFiles.selector';
import { ValidationPatterns } from '../utils/validationPatterns';

const StyledNoOptionsText = styled('div')(({ theme }) => ({
  fontSize: '12px'
}));

export function SongSharingUploadPage() {
  const path = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { addPreparedSharedUser, currentPreparedSharedUsers, deletePreparedSharedUser } = useUploadFilesSelector();
  const [emails, setEmails] = useState<string[]>([]);
  const [selectedType, setSelectedType] = React.useState('');
  const excludeEmails: string[] = currentPreparedSharedUsers?.map(sharedUser => sharedUser.email) ?? [];
  const filteredEmails = emails.filter(email => !excludeEmails.includes(email));

  useEffect(() => {
    sharingService.getSharedUsersAutofill().then(data => setEmails(data.emails));
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string().required('Email is required').matches(ValidationPatterns.email, 'Please enter a valid email'),
    accessType: Yup.string().required().oneOf(Object.values(SharedUserRoles), 'Invalid access type')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      accessType: SharedUserRoles.Downloader
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers) => {
      addPreparedSharedUser(values.email, values.accessType);

      formikHelpers.resetForm();
    }
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <>
      {!isMobile ? (
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={handleSubmit}>
            <Stack direction="row" alignItems="center">
              <Stack display={'flex'}>
                <Stack
                  flexDirection="row"
                  sx={{
                    width: '384px',
                    alignItems: 'center',
                    backgroundColor: 'rgba(75, 75, 75, 0.4)',
                    paddingLeft: '12px',
                    height: '40px',
                    borderTopLeftRadius: '32px',
                    borderBottomLeftRadius: '32px',
                    border: '1px solid #FFFFFF'
                  }}
                >
                  <Autocomplete
                    noOptionsText={<StyledNoOptionsText>No option</StyledNoOptionsText>}
                    fullWidth
                    autoComplete={false}
                    options={filteredEmails}
                    inputValue={formik.values.email}
                    value={formik.values.email}
                    clearOnBlur={false}
                    ListboxProps={{
                      sx: {
                        '& .MuiAutocomplete-option': {
                          paddingY: '0 !important',
                          fontSize: '12px !important',
                          maxHeight: '36px !important'
                        }
                      }
                    }}
                    sx={{
                      '& .MuiAutocomplete-endAdornment': {
                        display: 'none'
                      },
                      '& .MuiAutocomplete-inputRoot': {
                        paddingRight: '5px !important',
                        fontSize: '14px'
                      }
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        formik.handleSubmit();
                      }
                    }}
                    onChange={(event, value, reason) => {
                      formik.setFieldValue('email', reason === 'clear' ? '' : value);
                    }}
                    renderInput={params => (
                      <div style={{ position: 'relative' }}>
                        <TextField
                          margin="dense"
                          {...params}
                          placeholder="Email to"
                          size="small"
                          autoComplete="off"
                          variant="standard"
                          fullWidth
                          {...getFieldProps('email')}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            sx: {
                              '& .MuiAutocomplete-endAdornment': {
                                display: 'none'
                              }
                            }
                          }}
                          sx={{
                            '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
                              display: 'none'
                            }
                          }}
                        />
                      </div>
                    )}
                  />
                  <Select
                    {...getFieldProps('accessType')}
                    IconComponent={ArrowDown}
                    renderValue={selected => {
                      switch (selected) {
                        case SharedUserRoles.Viewer:
                          setSelectedType(SharedUserRoles.Viewer);
                          return AccessType.CanView;
                        case SharedUserRoles.Downloader:
                          setSelectedType(SharedUserRoles.Downloader);
                          return AccessType.CanDownload;
                        case SharedUserRoles.Editor:
                          setSelectedType(SharedUserRoles.Editor);
                          return AccessType.CanEdit;
                        default:
                          return '';
                      }
                    }}
                    sx={{
                      color: '#ABABAB',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      padding: '0px',
                      '& .MuiSelect-select': {
                        padding: '0px',
                        fontSize: '14px !important'
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        border: 0
                      },
                      '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                        border: 0
                      },
                      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 0
                      }
                    }}
                  >
                    <MenuItem
                      sx={{
                        position: 'relative',
                        paddingTop: '3px',
                        paddingLeft: '13px',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'flex-start',
                        width: '164px'
                      }}
                      value={SharedUserRoles.Viewer}
                    >
                      {AccessType.CanView}
                      <Typography sx={{ fontSize: '11px', color: '#757575', position: 'absolute', top: '50%', left: '13px' }}>
                        no downloading
                      </Typography>
                      {selectedType === SharedUserRoles.Viewer ? (
                        <Stack
                          sx={{
                            position: 'absolute',
                            right: '5px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '20px',
                            height: '20px'
                          }}
                        >
                          <Choice opacity={'50%'} />
                        </Stack>
                      ) : null}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        position: 'relative',
                        fontSize: '14px',
                        paddingTop: '3px',
                        paddingLeft: '13px',
                        display: 'flex',
                        alignItems: 'center',
                        width: '164px'
                      }}
                      value={SharedUserRoles.Downloader}
                    >
                      {AccessType.CanDownload}
                      {selectedType === SharedUserRoles.Downloader ? (
                        <Stack
                          sx={{
                            position: 'absolute',
                            right: '5px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '20px',
                            height: '20px'
                          }}
                        >
                          <Choice opacity={'50%'} />
                        </Stack>
                      ) : null}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        position: 'relative',
                        fontSize: '14px',
                        paddingTop: '3px',
                        paddingLeft: '13px',
                        display: 'flex',
                        alignItems: 'flex-start',
                        width: '164px'
                      }}
                      value={SharedUserRoles.Editor}
                    >
                      {AccessType.CanEdit}
                      <Typography sx={{ fontSize: '11px', color: '#757575', position: 'absolute', top: '50%', left: '13px' }}>
                        can add/delete files
                      </Typography>
                      {selectedType === SharedUserRoles.Editor ? (
                        <Stack
                          sx={{
                            position: 'absolute',
                            right: '5px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '20px',
                            height: '20px'
                          }}
                        >
                          <Choice opacity={'50%'} />
                        </Stack>
                      ) : null}
                    </MenuItem>
                  </Select>
                </Stack>
              </Stack>
              <Button
                sx={{
                  border: '1px solid #FFFFFF',
                  fontSize: '14px',
                  borderRadius: 0,
                  borderLeft: 0,
                  height: '40px',
                  paddingInline: '11px',
                  width: '80px',
                  '&.Mui-disabled': {
                    backgroundColor: 'info.main',
                    color: '#FFFF',
                    opacity: '50%'
                  }
                }}
                color="info"
                variant="contained"
                disabled
              >
                Share
              </Button>
              <Button
                disabled
                onClick={() => {}}
                style={{ minWidth: '40px', minHeight: '40px' }}
                sx={{
                  border: '1px solid #FFFFFF',
                  borderLeft: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: '32px',
                  borderBottomRightRadius: '32px',
                  padding: 0,
                  '&.Mui-disabled': {
                    backgroundColor: 'info.main',
                    color: '#FFFF',
                    opacity: '50%'
                  }
                }}
                color="info"
                variant="contained"
              >
                <Expand />
              </Button>
            </Stack>
            <Stack direction="row" mt={1} px={1} gap={1} maxWidth={'480px'} flexWrap={'wrap'}>
              {currentPreparedSharedUsers.map(sharedUser => (
                <Stack
                  sx={{ background: '#4B4B4B66' }}
                  height={'24px'}
                  direction="row"
                  key={sharedUser.email}
                  p={0.5}
                  pt={0.4}
                  borderRadius={4}
                  position={'relative'}
                >
                  <Typography fontSize={14} lineHeight={'17px'} fontWeight={400}>
                    {sharedUser.email}
                  </Typography>
                  <Button
                    sx={{
                      background: 'linear-gradient(270deg, #273640 56.85%, rgba(75, 75, 75, 0) 95.55%)',
                      position: 'absolute',
                      minWidth: 'unset',
                      width: '31px',
                      height: '24px',
                      top: 0,
                      right: 0,
                      borderRadius: 4,
                      justifyContent: 'right'
                    }}
                    onClick={() => deletePreparedSharedUser(sharedUser.email)}
                  >
                    <img src={'/assets/deleteIcon.svg'} alt="song" />
                  </Button>
                </Stack>
              ))}
            </Stack>
          </Form>
        </FormikProvider>
      ) : (
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => {}}
          disableElevation={true}
          sx={{
            height: '40px',
            padding: '10px 12px',
            fontSize: '16px'
          }}
        >
          Share
        </Button>
      )}
    </>
  );
}
