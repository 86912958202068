import { create } from 'zustand';
import { UserInfo } from '../@types/auth';
import { SongAccessData } from '../@types/songs';
import authService from '../services/authService';
import songsService from '../services/songsService';
import { SharedUserRoles } from '../@types/sharing';
import sharingService from '../services/sharingService';

type UserState = {
  user: UserInfo | null;
  userError: Error | null | string;
};

type UserActions = {
  getUser: () => Promise<void>;
};

export const useUserStore = create<UserState & UserActions>(set => ({
  user: null,
  userError: null,
  getUser: async () => {
    try {
      const user = await authService.getUserInfo();

      set({ user, userError: null });
    } catch (e: any) {
      set({ user: null, userError: e.message });
    }
  }
}));

type UserSongAccessState = {
  songAccessType: SharedUserRoles | null;
};

type UserSongAccessActions = {

  getSongAccessType: (songId: string) => Promise<SharedUserRoles | null>;
};

export const useUserSongAccessStore = create<UserSongAccessState & UserSongAccessActions>(set => ({
  songAccessType: null,
  getSongAccessType: async songId => {
    try {
      const songAccessType = await sharingService.getSongAccessType(songId);

      set({ songAccessType });
      return songAccessType 
    } catch (e: any) {
      set({ songAccessType: null });
      return null
    }
  }
}));
