 export const isValidAudioFile = (file: File): boolean => {
    return file.type.startsWith('audio/');
  };
  export const isZipFile = (file: File): boolean => {
    return file.type === 'application/zip' || file.name.endsWith('.zip');
  };
  export const readDirectory = (directoryEntry: FileSystemDirectoryEntry, files: File[]): Promise<void> => {
    return new Promise(resolve => {
      const reader = directoryEntry.createReader();
      reader.readEntries(async entries => {
        for (const entry of entries) {
          if (entry.isDirectory) {
            await readDirectory(entry as FileSystemDirectoryEntry, files);
          } else {
            files.push(await getFile(entry as FileSystemFileEntry));
          }
        }
        resolve();
      });
    });
  };
 export const getFile = (fileEntry: FileSystemFileEntry): Promise<File> => {
    return new Promise(resolve => {
      fileEntry.file(file => {
        resolve(file);
      });
    });
  };
 export const createFileList = (files: File[]): FileList => {
    const dataTransfer = new DataTransfer();
    files.forEach(file => {
      dataTransfer.items.add(file);
    });
    return dataTransfer.files;
  };